import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import useNavProps from '../../hooks/useNavProps';
import { BeneficiaryService } from '../../service/BeneficiaryService';
import BeneficiaryToolbar from './BeneficiaryToolbar';
import ProfileViewComponent from '../../components/profile/ProfileViewComponent';
import GeneralUtils from '../../utilities/GeneralUtils';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BeneficiaryResponsibleHistoryDialogComponent from '../../components/profile/BeneficiaryResponsibleHistoryDialogComponent';

const BeneficiaryView = () => {
    const [beneficiary, setBeneficiary] = useState(null);
    const [manager, setManager] = useState(null);
    const [assistant, setAssistant] = useState(null);
    const [psychologist, setPsyhologist] = useState(null);
    const [responsibleType, setResponsibleType] = useState(null);
    const [showHistoryDialog, setShowHistoryDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const historyMap = {
        CASE_MANAGER: "beneficiary.responsible.manager.history",
        SOCIAL_ASSISTANT: "beneficiary.responsible.assistant.history",
        PSYCHOLOGIST: "beneficiary.responsible.psychologist.history"
    };

    const toast = useRef(null);

    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

    const { beneficiaryIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', beneficiaryIdParam));
        // };        

        const getBeneficiary = async () => {
            await beneficiaryService.getBeneficiary(beneficiaryIdParam)
                .then(async (_data) => {
                    setBeneficiary(_data);

                    // await checkEditPermission()
                    await getBeneficiaryResponsible(beneficiaryIdParam, "CASE_MANAGER")
                    await getBeneficiaryResponsible(beneficiaryIdParam, "SOCIAL_ASSISTANT")
                    await getBeneficiaryResponsible(beneficiaryIdParam, "PSYCHOLOGIST")

                    setNavPropsValue('beneficiary', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getBeneficiaryResponsible = async (_beneficiaryId, _responsibleType) => {
            await beneficiaryService.getBeneficiaryResponsible(_beneficiaryId, _responsibleType)
                .then((_data) => {
                    if (_responsibleType === "CASE_MANAGER") {
                        setManager(_data)
                    } else if (_responsibleType === "SOCIAL_ASSISTANT") {
                        setAssistant(_data)
                    } else if (_responsibleType === "PSYCHOLOGIST") {
                        setPsyhologist(_data)
                    }
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        getBeneficiary();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryService, beneficiaryIdParam]);

    const getResponsibleButtons = (_responsible) => {
        return (
            <>
                <Button
                    text
                    className="aspc-button-icon-slim p-button p-button-icon-only ml-1"
                    onClick={() => onHistoryDialog(_responsible?.type)}
                    disabled={!_responsible}
                    tooltip={translatedMessage("beneficiary.responsible.history")}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <div className='flex align-items-center'>
                        <FontAwesomeIcon icon='fa-solid fa-clock-rotate-left' />
                    </div>
                </Button>
            </>
        )
    }

    const onHistoryDialog = (_type) => {
        setResponsibleType(_type)
        setShowHistoryDialog(true)
    }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <BeneficiaryToolbar beneficiary={beneficiary} code="DETAIL" />

                            <ProfileViewComponent entity={beneficiary} entityName="BENEFICIARY" detailTitle="beneficiary.details" />

                            <div className='w-full grid pcn-data-view-panel mb-5'>
                                <div className='col-12 md:col-6'>
                                    <div className='flex align-items-center w-100'>
                                        <span className='entity-view-subtitle mr-2'>
                                            {translatedMessage("beneficiary.responsible") + " / " + translatedMessage("beneficiary.caseManager")}                                            
                                        </span>
                                        {getResponsibleButtons(manager)}
                                    </div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(manager?.organizationProfile?.profile?.fullName)}</div>
                                </div>

                                <div className='col-12 md:col-6'>
                                    <div className='flex align-items-center w-100'>
                                        <span className='entity-view-subtitle mr-2'>{translatedMessage("beneficiary.socialAssistant")}</span>
                                        {getResponsibleButtons(assistant)}
                                    </div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(assistant?.organizationProfile?.profile?.fullName)}</div>
                                </div>

                                <div className='col-12 md:col-6'>
                                    <div className='flex align-items-center w-100'>
                                        <span className='entity-view-subtitle mr-2'>{translatedMessage("beneficiary.psychologist")}</span>
                                        {getResponsibleButtons(psychologist)}
                                    </div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(psychologist?.organizationProfile?.profile?.fullName)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <BeneficiaryResponsibleHistoryDialogComponent
                    beneficiary={beneficiary}
                    visible={showHistoryDialog}
                    visibleSetter={setShowHistoryDialog}
                    type={responsibleType}
                    title={translatedMessage(historyMap[responsibleType])}
                />
            </>
        );
    }
};

export default BeneficiaryView;
