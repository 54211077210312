import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { getIn, setIn, useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import { ProviderService } from '../../service/ProviderService';
import DataTableUtils from "../../utilities/DataTableUtils";
import EnumService from "../../service/EnumService";
import GeneralUtils from '../../utilities/GeneralUtils';

let emptyProvider = {
    id: null,
    name: '',
    description: '',
    type: 'PROVIDER',
    status: 'ACTIVE',
    cif: '',
    public: true,
    address: {
        id: null,
        county: null
    }
};

const ProviderEditDialog = (props) => {
    const [provider, setProvider] = useState(emptyProvider);
    const [isNew, setIsNew] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const yesNoDropdownOptions = [
        { label: translatedMessage('generic.yes'), value: true },
        { label: translatedMessage('generic.no'), value: false },
    ]
    const [counties, setCounties] = useState([]);
    const [filteredEntityLookups, setFilteredEntityLookups] = useState([]);

    const toast = useRef(null);

    const providerService = useMemo(() => new ProviderService(), []);

    useEffect(() => {
        let _provider = props.value ? props.value : emptyProvider;
        if (!_provider.cif) {
            _provider.cif = '';
        }
        setProvider(GeneralUtils.emptyInNullForObjectFields(_provider));
        setIsNew(props.value ? false : true);
        setStatuses(props?.statuses)

        const getCounties = async () => {
            const _counties = await EnumService.getEntityLookupByField("NOM:COUNTY")
            setCounties(_counties)
        };

        getCounties();
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveProvider = (data) => {
        providerService.saveProvider(data)
            .then((savedProvider) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedProvider, isNew);
                }
                toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: provider,
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = translatedMessage('form.error.nameRo.required');
            }
            if (!data.status) {
                errors.status = translatedMessage('form.error.provider.status.required');
            }
            if (data.public !== true && data.public !== false) {
                errors.public = translatedMessage('form.error.provider.isPublic.required');
            }
            if (!data.cif) {
                errors.cif = translatedMessage('form.error.organization.cif.required');
            }
            if (!data.address.county || !data.address.county.value) {
                errors = setIn(errors, 'address.county', translatedMessage('form.error.organization.county.required'));
            }
            return errors;
        },
        onSubmit: (data) => {
            saveProvider(data);
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredEntityLookups(_filteredItems);
    }

    const handleListChange = async (data, field) => {
        formik.setFieldValue(field, data.value)
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('provider.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                        {translatedMessage('generic.nameRo')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('name')}
                        </div>                        

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={statuses}></Dropdown>
                                    <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') })}>
                                        {translatedMessage('generic.status')}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('status')}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="public" name="public" value={formik.values.public} onChange={formik.handleChange} options={yesNoDropdownOptions}
                                        itemTemplate={(e) => DataTableUtils.yesNoTemplate(e.value)} />
                                    <label htmlFor="public" className={classNames({ 'p-error': isFormFieldValid('public') })}>
                                        {translatedMessage('provider.isPublic')}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('public')}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="cif" name="cif" value={formik.values.cif} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('cif') })} />
                                    <label htmlFor="cif" className={classNames({ 'p-error': isFormFieldValid('cif') })}>
                                        {translatedMessage('provider.cif')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('cif')}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="address.county"
                                        name="address.county"
                                        value={formik.values.address?.county}
                                        suggestions={filteredEntityLookups}
                                        completeMethod={(e) => searchListItems(e, counties)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        onChange={(d) => handleListChange(d, "address.county")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('address.county') })}
                                    />
                                    <label htmlFor="county" className={classNames({ 'p-error': isFormFieldValid('address.county') })}>
                                        {translatedMessage("generic.county")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('address.county')}
                        </div>

                        <div className='col-12'>
                            <div className="field pcn-sun-editor w-100 mb-0">
                                <span className="p-float-label">
                                    <InputTextarea id="description" name="description" rows={5} cols={30} value={formik.values.description} onChange={formik.handleChange} autoResize />
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>
                                        {translatedMessage('generic.description')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('description')}
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default ProviderEditDialog;
