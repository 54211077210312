import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import useNavProps from '../../hooks/useNavProps';
import ProfileViewComponent from '../../components/profile/ProfileViewComponent';
import { Button } from 'primereact/button';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import FamilyPlacementEditDialog from './FamilyPlacementEditDialog';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';
import FamilyPlacementToolbar from './FamilyPlacementToolbar';

const FamilyPlacementView = () => {
    const [familyPlacement, setFamilyPlacement] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const toast = useRef(null);

    const familyPlacementService = useMemo(() => new FamilyPlacementService(), []);

    const { fpIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', ampIdParam));
        // };        

        const getFP = async () => {
            await familyPlacementService.getFamilyPlacement(fpIdParam)
                .then(async (_data) => {
                    setFamilyPlacement(_data)

                    // await checkEditPermission()

                    setNavPropsValue('family-placement', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getFP();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyPlacementService, fpIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <FamilyPlacementToolbar familyPlacement={familyPlacement} code="DETAIL" />

                            <ProfileViewComponent entity={familyPlacement?.profile} entityName="FAMILY_PLACEMENT" detailTitle="person.details" />

                            <div className='w-full grid pcn-data-view-panel mb-5'>
                                <div className='col-12 flex align-items-center'>
                                    <span className='entity-view-subtitle mr-2'>{translatedMessage("familyPlacement.details")}</span>
                                    <Button
                                        text
                                        className="pcn-button-slim p-button p-button-icon-only"
                                        onClick={() => setShowEditDialog(true)}
                                        visible={hasPermission("FAMILY_PLACEMENT_EDIT")}
                                        disabled={!familyPlacement?.actions?.ADD_FAMILY_PLACEMENT}
                                    >
                                        <div className='flex align-items-center'>
                                            <FontAwesomeIcon icon='fa-solid fa-pencil' />
                                        </div>
                                    </Button>
                                </div>

                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('amp.householdDetail')}</div>
                                    <div className='filed-value'>
                                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(familyPlacement.householdDetail) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FamilyPlacementEditDialog
                    value={familyPlacement}
                    visible={showEditDialog}
                    afterSave={(savedData) => setFamilyPlacement(savedData)}
                    visibleSetter={() => setShowEditDialog(false)}
                    disableProfile={true}
                />
            </>
        );
    }
};

export default FamilyPlacementView;