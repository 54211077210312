import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import useNavProps from '../../hooks/useNavProps';
import { MonitoringService } from '../../service/MonitoringService';
import MonitoringToolbar from './MonitoringToolbar';
import MonitoringEditDialogComponent from '../../components/monitoring/MonitoringEditDialogComponent';
import MonitoringViewComponent from '../../components/monitoring/MonitoringViewComponent';

const MonitoringView = () => {
    const [monitoring, setMonitoring] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const toast = useRef(null);

    const monitoringService = useMemo(() => new MonitoringService(), []);

    const { monitoringIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', monitoringIdParam));
        // };        

        const getMonitoring = async () => {
            await monitoringService.getMonitoring(monitoringIdParam)
                .then(async (_data) => {
                    setMonitoring(_data);

                    // await checkEditPermission()

                    setNavPropsValue('monitoring', _data.id, _data?.type?.label)

                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getMonitoring();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monitoringService, monitoringIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <MonitoringToolbar monitoring={monitoring} code="DETAIL" />

                            <MonitoringViewComponent monitoring={monitoring} showEdit={true}/>
                        </div>
                    </div>
                </div>

                <MonitoringEditDialogComponent
                    value={monitoring}
                    visible={showEditDialog}
                    afterSave={(savedData) => setMonitoring(savedData)}
                    visibleSetter={() => setShowEditDialog(false)}
                />                
            </>
        );
    }
};

export default MonitoringView;
