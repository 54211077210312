import React, { useRef, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { translatedMessage } from '../service/LanguageService';
import ApiService from '../service/ApiService';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import Captcha from "react-google-recaptcha";
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import FooterBeforeLogin from '../components/FooterBeforeLogin';

const ResetPasswordRequest = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [captchaResponse, setCaptchaResponse] = useState("")
    const [captchaChecked, setCaptchaChecked] = useState(false)
    const [captchaInit, setCaptchaInit] = useState(true)

    useEffect(() => {
        setIsLoading(false)
    }, []);

    const formik = useFormik({
        initialValues: {
            username: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.username) {
                errors.username = translatedMessage("form.error.username.required");
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9._-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translatedMessage("form.error.username.invalid");
            }

            return errors;
        },
        onSubmit: (data) => {
            if (captchaChecked) {
                ApiService.instance.post('/user/request-reset', { username: data.username, captchaResponse: captchaResponse })
                    .then(async usernameResponse => {
                        toast?.current?.show({ severity: 'success', summary: translatedMessage("resetPassword.success.message").concat(usernameResponse.data), lifetime: 7000 });
                        setCaptchaChecked(false)
                        setCaptchaInit(true)
                    })
                    .catch(error => {
                        let errorMessage = "login.error.generic";
                        if (error && error.response && error.response.data) {
                            errorMessage = error.response.data.message
                        }
                        toast?.current?.show({ severity: 'error', summary: translatedMessage(errorMessage) });
                        setCaptchaChecked(false)
                        setCaptchaInit(true)
                    })
            } else {
                setCaptchaInit(false)
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const checkCaptchaResponse = async (value) => {
        setCaptchaResponse(value)
        setCaptchaChecked(true)
    }

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="pages-body login-page flex flex-column before-login-pages">
                    <div className='pcn-login-logos w-full flex justify-content-center py-1'></div>
                    <div className="align-self-center mt-auto mb-auto">
                        <div className="pages-panel card flex flex-column">
                            <div className="pages-header px-4 py-2 mb-0" style={{ height: '89px' }}>
                                <img src="assets/layout/images/logo.png" alt="logo" />
                            </div>

                            <h4 className='mb-0'>{translatedMessage("resetPassword.resetPassword")}</h4>

                            <form id="login-form" onSubmit={formik.handleSubmit}>
                                <div className="input-panel flex flex-column mb-3">
                                    <div className="field p-inputgroup mt-5 mb-1">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-envelope"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <InputText type="text" name="username" id="username" autoComplete="username"
                                                value={formik.values.username} onChange={formik.handleChange} autoFocus
                                                className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
                                            <label htmlFor="username">{translatedMessage("generic.username")}*</label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('username')}

                                    <Captcha sitekey={ApiService.getCaptchaSiteKey()} onChange={checkCaptchaResponse} className="pcn-captcha mt-4" />
                                    {!captchaInit && !captchaChecked &&
                                        <small className="p-error text-align-left">{translatedMessage("form.error.captcha.required")}</small>
                                    }
                                </div>
                            </form>

                            <Button className="login-button mb-3 px-3" label={translatedMessage("resetPassword.send")} form="login-form" type="submit"></Button>

                            <div className="grid">
                                <div className={"col ".concat(ApiService.getIsRegisterEnabled() ? "text-align-left" : "")}>
                                    <Link to="/login">{translatedMessage('login.login')}</Link>
                                </div>
                                {ApiService.getIsRegisterEnabled() &&
                                    <div className="col text-align-right">
                                        <Link to="/register">{translatedMessage('register.register')}</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <FooterBeforeLogin />
                </div>
            </>
        );
    }
};

export default ResetPasswordRequest;
