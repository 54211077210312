import React, { useState, useEffect, useRef } from 'react';
import { getIn, useFormik } from "formik";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { translatedMessage } from '../../service/LanguageService';
import ValidationService from '../../service/ValidationServices';
import { HearingService } from '../../service/HearingService';
import { NoticeService } from '../../service/NoticeService';

let emptyRecord = {
    id: null,
    notice: null,
    organizationProfile: null,
    date: null,
    firstName: '',
    lastName: '',
    pin: '',
    birthday: null,
    homeAddress: '',
    motherName: '',
    fatherName: '',
    type: 'VICTIM',
    offenseName: '',
    hearingLocation: null,
    hearingSpace: '',
    comment: '',
    conclusion: null,
    folder: null
};

const HearingEditComponent = (props) => {
    const [hearing, setHearing] = useState()
    const [conclusionList, setConclusionList] = useState([])
    const [hearingTypeList, setHearingTypeList] = useState([])
    const [hearingLocationList, setHearingLocationList] = useState([])

    const [filteredNotices, setFilteredNotices] = useState([]);

    const hearingService = new HearingService();
    const noticeService = new NoticeService();

    const toast = useRef(null);


    useEffect(() => {
        const getConclusionList = async () => {
            const _conclusionList = await EnumService.getEntityLookupByField("NOM:HEARING_CONCLUSION")
            setConclusionList(_conclusionList)
        }

        const getHearingTypeList = async () => {
            const _hearingTypeList = await EnumService.getEnumByName("HearingType")
            setHearingTypeList(_hearingTypeList)
        }

        const getHearingLocationList = async () => {
            const _hearingLocationList = await EnumService.getEnumByName("HearingLocation")
            setHearingLocationList(_hearingLocationList)
        }

        const setupHearing = async () => {
            let _hearing = props.value;
            if (!_hearing) {
                _hearing = { ...emptyRecord };                
            } else {
                _hearing = GeneralUtils.emptyInNullForObjectFields(_hearing);
                if(_hearing.notice) {
                    _hearing.notice.displayName = _hearing.notice.noticeType.label +                         
                        " - " + GeneralUtils.formatDate(_hearing.notice.noticeDate) + 
                        " - " + _hearing.notice.reporterType.label + 
                        " - " + _hearing.notice.reportMode.label + 
                        (_hearing.notice.beneficiary ? " - " + _hearing.notice.beneficiary.profile.fullName : "") +
                        (_hearing.notice.reporterProfile ? " - " + _hearing.notice.reporterProfile.profile?.fullName : "")
                }
            }
            setHearing(_hearing);

            if (props?.conclusionList) {
                setConclusionList(props?.conclusionList);
            } else {
                await getConclusionList();
            }
            if (props?.hearingTypeList) {
                setHearingTypeList(props?.hearingTypeList)
            } else {
                await getHearingTypeList();
            }
            if (props?.hearingLocationList) {
                setHearingLocationList(props?.hearingLocationList);
            } else {
                await getHearingLocationList();
            }
        }

        setupHearing();
    }, [props]);

    const formik = useFormik({
        initialValues: hearing ? hearing : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.date) {
                errors.date = translatedMessage("form.error.hearingDate.required");
            }
            if (!data.firstName) {
                errors.firstName = translatedMessage("form.error.firstName.required");
            }
            if (!data.lastName) {
                errors.lastName = translatedMessage("form.error.lastName.required");
            }
            if (!data.pin) {
                errors.pin = translatedMessage("form.error.pin.required");
            } else {
                let validationResponse = ValidationService.validateCNP(data.pin)
                if (!validationResponse.success) {
                    errors.pin = translatedMessage(validationResponse.message);
                } else {
                    data.birthday = GeneralUtils.computeBirthDayFromPin(data.pin)
                    setHearing(data)
                }
            }

            if (!data.type) {
                errors.type = translatedMessage("form.error.hearingType.required");
            }
            if (!data.conclusion) {
                errors.conclusion = translatedMessage("form.error.hearingConclusion.required");
            }            

            return errors;
        },
        onSubmit: (data) => {
            // let _profile = GeneralUtils.nullIfEmptyForObjectFields({ ...data.responsibleProfile })
            // _profile.birthCountyId = _profile.birthCounty && _profile.birthCounty.value ? _profile.birthCounty.value : _profile.birthCountyId;
            //_profile.pin = _profile.pin ? _profile.pin : null
            // let _hearing = { ...data };
            // _hearing.responsibleProfile = _profile;
            // _hearing.beneficiary = _beneficiary;
            // _hearing = GeneralUtils.nullIfEmptyForObjectFields(_hearing);

            hearingService.saveHearing(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const handleNumberValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (charCode === null || /^[0-9\b]+$/.test(charCode)) {
            formik.handleChange(event);
        }
    }

    const handleListChange = async (data, field) => {
        formik.setFieldValue(field, data.value)
    }

    const searchNotices = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "id";
        criteria.name = query.toLowerCase();

        noticeService.getNoticesByCriteria(criteria)
            .then((result) => {
                result.items.map(item => {
                    item.displayName = item.noticeType.label +                         
                        " - " + GeneralUtils.formatDate(item.noticeDate) + 
                        " - " + item.reporterType.label + 
                        " - " + item.reportMode.label + 
                        (item.beneficiary ? " - " + item.beneficiary.profile.fullName : "") +
                        (item.reporterProfile ? " - " + item.reporterProfile.profile?.fullName : "")

                    return item
                })
                setFilteredNotices(result.items);
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="flex flex-column align-items-center">
                <div className="col-12">
                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>                            
                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="lastName" id="lastName"
                                            value={formik.values.lastName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} autoFocus />
                                        <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>
                                            {translatedMessage("generic.lastName")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('lastName')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="firstName" id="firstName"
                                            value={formik.values.firstName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('firstName') })} />
                                        <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>
                                            {translatedMessage("generic.firstName")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('firstName')}
                            </div>                            

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="pin" id="pin"
                                            value={formik.values.pin} onChange={handleNumberValidation}
                                            className={classNames({ 'p-invalid': isFormFieldValid('pin') })}
                                            maxLength={13} />
                                        <label htmlFor="pin" className={classNames({ 'p-error': isFormFieldValid('pin') })}>
                                            {translatedMessage("profile.pin")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('pin')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="birthday" name="birthday" value={formik.values.birthday} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('birthday') })} />
                                        <label htmlFor="birthday" className={classNames({ 'p-error': isFormFieldValid('birthday') })}>
                                            {translatedMessage("profile.birthday")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('birthday')}
                            </div>

                            <div className="col-12">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="homeAddress" id="homeAddress"
                                            value={formik.values.homeAddress} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('homeAddress') })} />
                                        <label htmlFor="homeAddress" className={classNames({ 'p-error': isFormFieldValid('homeAddress') })}>
                                            {translatedMessage("hearing.homeAddress")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('homeAddress')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="motherName" id="motherName"
                                            value={formik.values.motherName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('motherName') })} />
                                        <label htmlFor="motherName" className={classNames({ 'p-error': isFormFieldValid('motherName') })}>
                                            {translatedMessage("hearing.motherName")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('motherName')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="fatherName" id="fatherName"
                                            value={formik.values.fatherName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fatherName') })} />
                                        <label htmlFor="fatherName" className={classNames({ 'p-error': isFormFieldValid('fatherName') })}>
                                            {translatedMessage("hearing.fatherName")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('fatherName')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="type" name="type" value={formik.values.type}
                                            options={hearingTypeList} onChange={formik.handleChange} />
                                        <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') })}>
                                            {translatedMessage("hearing.type")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('type')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="offenseName" id="offenseName"
                                            value={formik.values.offenseName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('offenseName') })}
                                              />
                                        <label htmlFor="offenseName" className={classNames({ 'p-error': isFormFieldValid('offenseName') })}>
                                            {translatedMessage("hearing.offenseName")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('offenseName')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="hearingLocation" name="hearingLocation" value={formik.values.hearingLocation}
                                            options={hearingLocationList} onChange={formik.handleChange} />
                                        <label htmlFor="hearingLocation" className={classNames({ 'p-error': isFormFieldValid('hearingLocation') })}>
                                            {translatedMessage("hearing.hearingLocation")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('hearingLocation')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="hearingSpace" id="hearingSpace"
                                            value={formik.values.hearingSpace} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('hearingSpace') })}
                                              />
                                        <label htmlFor="hearingSpace" className={classNames({ 'p-error': isFormFieldValid('hearingSpace') })}>
                                            {translatedMessage("hearing.hearingSpace")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('hearingSpace')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="date" name="date" value={formik.values.date} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('date') })}/>
                                        <label htmlFor="date" className={classNames({ 'p-error': isFormFieldValid('date') })}>
                                            {translatedMessage("hearing.date")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('date')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="conclusion.code" name="conclusion.code" value={formik.values.conclusion?.code} onChange={formik.handleChange}
                                            options={conclusionList} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('conclusion') })} />
                                        <label htmlFor="conclusion.code" className={classNames({ 'p-error': isFormFieldValid('conclusion') })}>
                                            {translatedMessage('hearing.conclusion')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('conclusion')}
                            </div>

                            <div className="col-12">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputTextarea name="comment" id="comment" value={formik.values.comment} onChange={formik.handleChange} rows={5}
                                            className={classNames({ 'p-invalid': isFormFieldValid('comment') })} />
                                        <label htmlFor="comment" className={classNames({ 'p-error': isFormFieldValid('comment') })}>
                                            {translatedMessage("hearing.comment")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('comment')}
                            </div>                            

                            <div className='col-12'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <AutoComplete
                                            dropdown
                                            id="notice"
                                            name="notice"
                                            value={formik.values.notice}
                                            suggestions={filteredNotices}
                                            completeMethod={(e) => searchNotices(e)}
                                            virtualScrollerOptions={{ itemSize: 38 }}
                                            field="displayName"
                                            onChange={(d) => handleListChange(d, "notice")}
                                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                            className={classNames({ 'p-invalid': isFormFieldValid('notice') })}
                                        />
                                        <label htmlFor="notice" className={classNames({ 'p-error': isFormFieldValid('notice') })}>
                                            {translatedMessage("hearing.notice")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('notice')}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default HearingEditComponent;
