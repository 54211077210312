import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { getIn, useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import { CenterService } from '../../service/CenterService';
import { CenterBeneficiaryService } from '../../service/CenterBeneficiaryService';
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';
import { InputTextarea } from 'primereact/inputtextarea';

let emptyCenterBeneficiary = {
    id: null,
    startDate: null,
    endDate: null,
    comment: '',
    center: {
        id: null,
        name: ''
    },
    beneficiary: {
        id: null,
        profile: {
            id: null,
            fullName: ''
        }
    }
};

const CenterBeneficiaryEditDialog = (props) => {
    const [centerBeneficiary, setCenterBeneficiary] = useState(emptyCenterBeneficiary);
    const [isNew, setIsNew] = useState(false);
    const [filteredCenters, setFilteredCenters] = useState([]);

    const toast = useRef(null);

    const centerBeneficiaryService = useMemo(() => new CenterBeneficiaryService(), []);
    const centerService = useMemo(() => new CenterService(), []);

    useEffect(() => {
        let _centerBeneficiary = props.value;
        if (!_centerBeneficiary) {
            _centerBeneficiary = { ...emptyCenterBeneficiary };
            if (props?.center) { _centerBeneficiary.center = props?.center; }
            if (props?.beneficiary) { _centerBeneficiary.beneficiary = props?.beneficiary; }
        }

        setCenterBeneficiary(_centerBeneficiary);
        setIsNew(props.value ? false : true);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveCenterBeneficiary = async (data) => {
        await centerBeneficiaryService.saveCenterBeneficiary(data)
            .then((savedCenterBeneficiary) => {                                    
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedCenterBeneficiary, isNew);
                }
                toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.save.success') });

                hideDialog();
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: centerBeneficiary,
        validate: (data) => {
            let errors = {};

            if (!data.center?.id) {
                errors.center = translatedMessage('form.error.center.required');
            }
            if (!data.beneficiary?.id) {
                errors.beneficiary = translatedMessage('form.error.beneficiary.required');
            }
            if (!data.startDate) {
                errors.startDate = translatedMessage('form.error.startDate.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveCenterBeneficiary(data);
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );



    const searchCenters = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "name";
        criteria.name = query.toLowerCase();
        criteria.pageSize = 50;

        centerService.getCentersByCriteria(criteria)
            .then((result) => {
                setFilteredCenters(result.items);
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    const handleBeneficiaryChange = async (_beneficiary) => {
        setCenterBeneficiary({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('centerBeneficiary.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="center"
                                        name="center"
                                        value={formik.values.center}
                                        suggestions={filteredCenters}
                                        completeMethod={(e) => searchCenters(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="name"
                                        onChange={formik.handleChange}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('center') })}
                                        autoFocus
                                        disabled={props?.center}
                                    />
                                    <label htmlFor="center" className={classNames({ 'p-error': isFormFieldValid('center') })}>
                                        {translatedMessage("centerBeneficiary.center")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('center')}
                        </div>

                        <BeneficiaryAutocompleteFieldComponent
                            beneficiary={formik.values.beneficiary}
                            setBeneficiary={(e) => handleBeneficiaryChange(e)}
                            validationError={isFormFieldValid('beneficiary')}
                            disabled={props?.beneficiary}
                        />

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="startDate" name="startDate" value={formik.values.startDate} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} readOnlyInput />
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                        {translatedMessage("generic.startDate")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('startDate')}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="endDate" name="endDate" value={formik.values.endDate} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} readOnlyInput />
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                        {translatedMessage("generic.endDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('endDate')}
                        </div>

                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputTextarea id="comment" name="comment" rows={3} cols={30} value={formik.values.comment} onChange={formik.handleChange} autoResize 
                                        className={classNames({ 'p-invalid': isFormFieldValid('comment') })}/>
                                    <label htmlFor="comment" className={classNames({ 'p-error': isFormFieldValid('comment') })}>
                                        {translatedMessage('centerBeneficiary.comment')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('comment')}
                        </div>                        
                    </div>
                </form>
            </Dialog>
        </>);
};

export default CenterBeneficiaryEditDialog;
