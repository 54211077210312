import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import FamilyPlacementBeneficiaryListComponent from '../../components/familyPlacement/FamilyPlacementBeneficiaryListComponent';
import FamilyPlacementToolbar from './FamilyPlacementToolbar';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { ProgressSpinner } from 'primereact/progressspinner';
import useNavProps from '../../hooks/useNavProps';


const FamilyPlacementBeneficiaryList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [familyPlacement, setFamilyPlacement] = useState(null);

    const toast = useRef(null);
    const { setNavPropsValue } = useNavProps();

    const familyPlacementService = useMemo(() => new FamilyPlacementService(), []);
    const { fpIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getFP = async () => {
            await familyPlacementService.getFamilyPlacement(fpIdParam)
                .then(async (_data) => {
                    setFamilyPlacement(_data)

                    // await checkEditPermission()
                    setNavPropsValue('family-placement', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(true)
                    toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        getFP();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyPlacementService, fpIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <>
                <div className="grid h-full">
                    <Toast ref={toast} />
                    <div className="col-12">
                        <div className="card h-full">
                            <FamilyPlacementToolbar familyPlacement={familyPlacement} code="BENEFICIARY" />

                            <FamilyPlacementBeneficiaryListComponent familyPlacement={familyPlacement} pageTitle={translatedMessage("beneficiary.beneficiaries")}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default FamilyPlacementBeneficiaryList;