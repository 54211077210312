import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import useNavProps from '../../hooks/useNavProps';
import ProfileExtendedViewComponent from '../../components/profile/ProfileExtendedViewComponent';
import { AmpService } from '../../service/AmpService';
import AmpToolbar from './AmpToolbar';

const AmpProfileExtendedView = () => {
    const [amp, setAmp] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const ampService = useMemo(() => new AmpService(), []);

    const { ampIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', profileIdParam));
        // };

        const getAmp = async () => {
            await ampService.getAmp(ampIdParam)
                .then(async (_data) => {
                    setAmp(_data);

                    // await checkEditPermission()

                    setNavPropsValue('amp', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getAmp();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ampService, ampIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <AmpToolbar amp={amp} code="EXTENDED" />

                            <ProfileExtendedViewComponent entity={amp.profile} entityName="AMP"/>
                        </div>
                    </div>
                </div>                
            </>
        );
    }
};

export default AmpProfileExtendedView;
