import React, { useState, useEffect, useRef, useMemo } from 'react';
import GenericTableView from '../components/GenericTableView';
import { DashboardService } from '../service/DashboardService';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../service/LanguageService';
import OrganizationDataTableUtils from "../utilities/OrganizationDataTableUtils";
import DashboardBeneficiaryList from './dashboard/DashboardBeneficiaryList';
import { hasPermission } from '../service/UserService';
import DashboardNoticeList from './dashboard/DashboardNoticeList';
import DashboardHearingList from './dashboard/DashboardHearingList';

const myOrganizationProfilesColumns = [
    { name: "generic.nameRo", fieldName: "organization.name" },
    { name: "organizationProfile.position", fieldName: "position", template: (op) => { return OrganizationDataTableUtils.getOrganizationProfilePositionDisplayName(op) } },
    { name: null, fieldName: "organization.id", url: (op) => `/${op.organization?.type?.toLowerCase()}/${op.organization.id}`, isActionButton: true }
];

const HomePage = () => {
    const [dashboard, setDashboard] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const toast = useRef(null);

    const dashboardService = useMemo(() => new DashboardService(), []);


    useEffect(() => {
        setIsLoading(true);
        const getDashboarResult = async () => {
            await dashboardService
                .getDashboardResult()
                .then((_result) => {
                    setDashboard(_result);
                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getDashboarResult();
        dashboardService.getDashboardResult();
    }, [dashboardService]);


    return (
        <>
            <Toast ref={toast} />
            <div className="grid">

                <div className="col-12 lg:col-6">
                    {hasPermission('BENEFICIARY_LIST') &&
                        <DashboardBeneficiaryList />
                    }

                    {dashboard.myOrganizationProfiles && dashboard.myOrganizationProfiles.totalCount > 0 &&
                        <div className="card mb-3">
                            <GenericTableView
                                pageTitle={translatedMessage('dashboard.myOrganizationProfile')}
                                result={dashboard.myOrganizationProfiles}
                                columns={myOrganizationProfilesColumns}
                                loading={isLoading}
                                hasEntityLink={hasPermission('ORGANIZATION_VIEW') ? true : false}
                            />
                        </div>
                    }
                </div>

                <div className="col-12 lg:col-6">
                    {hasPermission('NOTICE_LIST') &&
                        <DashboardNoticeList />
                    }

                    {hasPermission('HEARING_LIST') &&
                        <DashboardHearingList />
                    }                    
                </div>

                {/* {dashboard.myReportedNotices && dashboard.myReportedNotices.totalCount > 0 &&
                    <div className="col-12 lg:col-6">
                        <div className="card mb-3">
                            <GenericTableView pageTitle={translatedMessage('dashboard.myReportedNotices')} result={dashboard.myReportedNotices} columns={myReportedNoticesColumns} loading={isLoading} />
                        </div>
                    </div>
                } */}

                {/* {dashboard.legalRepresentativeForBeneficiaries && dashboard.legalRepresentativeForBeneficiaries.totalCount > 0 &&
                    <div className="col-12 lg:col-6">
                        <div className="card mb-3">
                            <GenericTableView pageTitle={translatedMessage('dashboard.legalRepresentativeForBeneficiaries')} result={dashboard.legalRepresentativeForBeneficiaries} columns={legalRepresentativeForBeneficiariesColumns} loading={isLoading} />
                        </div>
                    </div>
                } */}

                {/* {dashboard.myFamilyPlacements && dashboard.myFamilyPlacements.totalCount > 0 &&
                    <div className="col-12 lg:col-6">
                        <div className="card mb-3">
                            <GenericTableView pageTitle={translatedMessage('dashboard.myFamilyPlacements')} result={dashboard.myFamilyPlacements} columns={myFamilyPlacementsColumns} loading={isLoading} />
                        </div>
                    </div>
                } */}

                {/* {dashboard.responsibleForAmps && dashboard.responsibleForAmps.totalCount > 0 &&
                    <div className="col-12 lg:col-6">
                        <div className="card mb-3">
                            <GenericTableView pageTitle={translatedMessage('dashboard.responsibleForAmps')} result={dashboard.responsibleForAmps} columns={responsibleForAmpsColumns} loading={isLoading} />
                        </div>
                    </div>
                } */}

                {/* {dashboard.myAmps && dashboard.myAmps.totalCount > 0 &&
                    <div className="col-12 lg:col-6">
                        <div className="card mb-3">
                            <GenericTableView pageTitle={translatedMessage('dashboard.myAmps')} result={dashboard.myAmps} columns={myAmpsColumns} loading={isLoading} />
                        </div>
                    </div>
                } */}

                {/* {dashboard.myRelatedProfiles && dashboard.myRelatedProfiles.totalCount > 0 &&
                    <div className="col-12 lg:col-6">
                        <div className="card mb-3">
                            <GenericTableView pageTitle={translatedMessage('dashboard.myRelatedProfiles')} result={dashboard.myRelatedProfiles} columns={myRelatedProfilesColumns} loading={isLoading} />
                        </div>
                    </div>
                } */}
            </div >
        </>
    );
};

export default HomePage;
