import React, { useEffect, useState, useMemo, useRef } from 'react';
import { translatedMessage } from '../../service/LanguageService';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import DataTableUtils from '../../utilities/DataTableUtils';
import { AmpService } from '../../service/AmpService';

const AmpAutocompleteFieldComponent = (props) => {
    const [amp, setAmp] = useState()
    const [ampList, setAmpList] = useState([])
    const [validationError, setValidationError] = useState(false);

    const toast = useRef(null);
    const ampService = useMemo(() => new AmpService(), [])

    useEffect(() => {
        const getData = async () => {
            await search()
        }
        getData()

        setValidationError(props?.validationError)

        if (props?.amp) {
            let _amp = { ...props.amp }

            setAmp(_amp)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const search = async (e = null) => {
        let criteria = {}
        criteria.pageSize = DataTableUtils.maxRowsForSearch();        

        if (e?.query) {
            criteria.fullName = e.query
        }

        await ampService.getAmpList(criteria)
            .then((data) => {
                setAmpList(data.items);
                return data
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const handleChange = async (_selected) => {
        setAmp(_selected)

        if (_selected?.id && typeof props.setAmp === 'function') {
            //console.log("_selected: ", _selected)
            props.setAmp(_selected);
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12 flex flex-row">
                <div className='flex-grow-1'>
                    <div className="p-inputgroup">
                        <span className="p-float-label">
                            <AutoComplete
                                dropdown
                                id="amp"
                                name="amp"
                                value={amp}
                                suggestions={ampList}
                                completeMethod={(e) => search(e)}
                                virtualScrollerOptions={{ itemSize: 38 }}
                                field={"profile.fullName"}
                                onChange={(d) => handleChange(d.value)}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                className={validationError ? 'p-invalid' : ''}
                                disabled={props?.disabled}
                            />
                            <label htmlFor="amp" className={validationError ? 'p-error' : ''}>
                                {props?.title ? props?.title : translatedMessage("profile.search")}
                            </label>
                        </span>
                    </div>
                    <small className={"p-error text-align-left mt-1 ".concat(validationError ? "block" : "hidden")}>
                        {translatedMessage("form.error.profile.required")}
                    </small>
                </div>
            </div>
        </>
    );
};

export default AmpAutocompleteFieldComponent;
