import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { hasPermission } from '../../service/UserService';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { ProviderService } from '../../service/ProviderService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProviderEditDialog from "../../components/provider/ProviderEditDialog";
import ProviderToolbar from './ProviderToolbar';
import DataTableUtils from "../../utilities/DataTableUtils";
import EnumService from "../../service/EnumService";

const ProviderView = () => {
    const [provider, setProvider] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [providerStatuses, setProviderStatuses] = useState([]);

    const toast = useRef(null);

    const providerService = useMemo(() => new ProviderService(), []);

    const { providerIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getProviderStatuses = async () => {
            setProviderStatuses(await EnumService.getEnumByName("OrganizationStatus"));
        };

        const getProvider = async () => {
            await providerService.getProvider(providerIdParam)
                .then(async (_data) => {
                    setProvider(_data);
                    setNavPropsValue('provider', _data.id, _data.name);
                    setIsLoading(false);
                    getProviderStatuses();
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getProvider();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerService, providerIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <ProviderToolbar provider={provider} code="DETAIL" />

                                <div className='w-full grid pcn-data-view-panel mb-5'>
                                    <div className='col-12 flex align-items-center'>
                                        <span className='entity-view-subtitle mr-2'>{translatedMessage("provider.details")}</span>
                                        <Button
                                            text
                                            className="pcn-button-slim p-button p-button-icon-only"
                                            onClick={() => setShowEditDialog(true)}
                                            visible={hasPermission('ORGANIZATION_EDIT')}
                                            disabled={!provider?.actions?.ADD_ORGANIZATION}
                                        >
                                            <div className='flex align-items-center'>
                                                <FontAwesomeIcon icon='fa-solid fa-pencil' />
                                            </div>
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('generic.nameRo')}</div>
                                        <div className='filed-value'>{provider.name}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('provider.cif')}</div>
                                        <div className='filed-value'>{provider.cif}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('provider.isPublic')}</div>
                                        <div className='filed-value'>{DataTableUtils.yesNoTemplate(provider.public)}</div>
                                    </div>                                    
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('generic.county')}</div>
                                        <div className='filed-value'>{provider.address?.county?.label}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('generic.status')}</div>
                                        <div className='filed-value'>
                                            <span className={`pill status status-${provider.status.toLowerCase()}`}>{translatedMessage("OrganizationStatus." + provider.status)}</span>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('generic.description')}</div>
                                        <div className='filed-value'>{provider.description}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <ProviderEditDialog
                        value={provider}
                        visible={showEditDialog}
                        afterSave={(savedData) => setProvider(savedData)}
                        visibleSetter={() => setShowEditDialog(false)}
                        statuses={providerStatuses}
                    />
                </>
            )}
        </>
    );
};

export default ProviderView;
