import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { translatedMessage } from '../service/LanguageService';

const UserGuide = () => {
    useEffect(() => {});

    const showUserGuide = () => {
        window.open('https://prahova.esocial.ro/ghid/');
    };

    return (
        <>
            <Button className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link" 
                onClick={showUserGuide} tooltip={translatedMessage('candidate.guide')} tooltipOptions={{ position: 'top' }}>
                <span className="p-overlay-badge pcn-notification-badge">
                    <i className="pi pi-question-circle fs-large"></i>
                </span>
            </Button>
        </>
    );
};

export default UserGuide;
