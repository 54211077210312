import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';
import useAuth from '../../hooks/useAuth';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import PasswordRequirement from '../../components/PasswordRequirementComponent'

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';

const ChangePassword = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error] = useState({ isError: false, message: '' });
    const { auth } = useAuth();
    const toast = useRef(null);

    const userService = new UserService();

    useEffect(() => {
        setIsLoading(false);
    }, [auth]);

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            confirmPassword: '',
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};            

            if (!data.currentPassword) {
                errors.currentPassword = translatedMessage("form.error.currentPassword.required");;
            }

            if (!data.password) {
                errors.password = translatedMessage("form.error.newPassword.required");;
            }
            else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/i.test(data.password.replace(" ", ""))) {
                errors.password = translatedMessage("form.error.password.invalid");
            }

            if (!data.confirmPassword) {
                errors.confirmPassword = translatedMessage("form.error.confirmPassword.required");
            }
            else if (data.password !== data.confirmPassword) {
                errors.confirmPassword = translatedMessage("form.error.confirmPassword.noMatch");
            }            

            return errors;
        },
        onSubmit: (data) => {
            userService.changePassword({ oldPassword: data.currentPassword, newPassword: data.password})
                .then(() => {
                    formik.resetForm();
                    toast?.current?.show({ severity: 'success', summary: translatedMessage("profile.password.save.success") });
                })
                .catch(error => {
                    formik.resetForm();
                    toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const passwordFooter = <PasswordRequirement />;

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            < div className="grid" >
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card">
                        <div className='w-full text-align-center mb-5'>
                            <h5>{translatedMessage("profile.changePassword")}</h5>
                        </div>

                        <div className="flex flex-column align-items-center">
                            <div className="col-12 lg:col-9 xl:col-6">
                                <form id="profile-form" onSubmit={formik.handleSubmit}>
                                    <div className="input-panel flex flex-column mb-5">
                                        <div className="p-inputgroup mt-5 mb-2">
                                            <span className="p-float-label">
                                                <InputText type="password" name="currentPassword" id="current-password" autoComplete="current-password"
                                                    value={formik.values.currentPassword} onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('currentPassword') })} />
                                                <label htmlFor="current-password"  className={classNames({ 'p-error': isFormFieldValid('currentPassword') })}>
                                                    {translatedMessage("generic.currentPassword")}*
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('currentPassword')}
                                        
                                        <div className="p-inputgroup mt-3 mb-2">
                                            <span className="p-float-label">
                                                <Password type="password" name="password" id="new-password" autoComplete="new-password"
                                                    value={formik.values.password} onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('password') })}
                                                    toggleMask footer={passwordFooter} />
                                                <label htmlFor="new-password" className={classNames({ 'p-error': isFormFieldValid('password') })}>
                                                    {translatedMessage("generic.newPassword")}*
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('password')}

                                        <div className="p-inputgroup mt-3 mb-2">
                                            <span className="p-float-label">
                                                <InputText type="password" name="confirmPassword" id="confirmPassword"
                                                    value={formik.values.confirmPassword} onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })} />
                                                <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>
                                                    {translatedMessage("generic.confirmPassword")}*
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('confirmPassword')}                                        
                                    </div>
                                </form>

                            </div>
                            <div className='w-full text-align-center mb-5'>
                                <Button className="login-button px-3" label={translatedMessage("generic.save")} form="profile-form" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
};

export default ChangePassword;
