import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { ServicesService } from "../../service/ServicesService";
import { ServiceExpertBeneficiaryService } from "../../service/ServiceExpertBeneficiaryService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import GeneralUtils from "../../utilities/GeneralUtils";
import DeleteModalComponent from "../DeleteModalComponent";
import ServiceExpertBeneficiaryEditDialog from "./ServiceExpertBeneficiaryEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import ServiceDataTableUtils from "../../utilities/ServiceDataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import EnumService from "../../service/EnumService";
import ServiceExpertBeneficiaryViewDialogComponent from "./ServiceExpertBeneficiaryViewDialogComponent";
import ServiceSupplyListDialogComponent from "../serviceSupply/ServiceSupplyListDialogComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecurityService from '../../service/SecurityService';

const ServiceExpertBeneficiaryListComponent = (props) => {
    const [serviceExpertBeneficiaryList, setServiceExpertBeneficiaryList] = useState([]);
    const [selectedServiceExpertBeneficiary, setSelectedServiceExpertBeneficiary] = useState(null);
    const [serviceVenues, setServiceVenues] = useState([]);
    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [supplyDialogVisible, setSupplyDialogVisible] = useState(false);

    const [canCreate, setCanCreate] = useState(true);

    const [totalRecords, setTotalRecords] = useState(0);
    const toast = useRef(null);
    const dt = useRef(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            serviceName: { value: null, matchMode: FilterMatchMode.IN },
            expert: { value: null, matchMode: FilterMatchMode.CONTAINS },
            beneficiaryProfileName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            objective: { value: null, matchMode: FilterMatchMode.CONTAINS },
            serviceVenueName: { value: null, matchMode: FilterMatchMode.IN }
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        serviceName: "service_name",
        expert: "expert",
        beneficiaryProfileName: "beneficiary_profile_name",
        startDate: "start_date",
        endDate: "end_date",
        serviceVenueName: "service_venue_name",
    };

    const navigate = useNavigate();

    const servicesService = useMemo(() => new ServicesService(), []);
    const serviceExpertBeneficiaryService = useMemo(() => new ServiceExpertBeneficiaryService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getServiceVenues = async () => {
            const _serviceVenues = await EnumService.getEntityLookupByField("NOM:SERVICE_VENUE");
            setServiceVenues(_serviceVenues);
        };

        const checkCanServiceSupply = async () => {
            if (props?.beneficiary?.id) {
                setCanCreate(await SecurityService.check('ADD_BENEFICIARY_SERVICES', props?.beneficiary?.id));
            } else {
                setCanCreate(await SecurityService.check('ADD_BENEFICIARY_SERVICES'));
            }
        };

        getServiceVenues();
        checkCanServiceSupply()

        setFirstLoading(false);

    }, [servicesService, props]);

    useEffect(() => {
        loadServiceExpertBeneficiaryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("ServiceExpertBeneficiaryList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";

        criteria.serviceId = props?.service?.id;
        criteria.beneficiaryId = props?.beneficiary?.id;
        criteria.expertProfileId = props?.expertProfile?.id;

        criteria.serviceName = lazyParams.filters.serviceName.value;

        criteria.expert = lazyParams.filters.expert.value;
        criteria.beneficiaryName = lazyParams.filters.beneficiaryProfileName.value;
        criteria.venues = lazyParams.filters.serviceVenueName.value;

        return criteria;
    };

    const loadServiceExpertBeneficiaryList = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await serviceExpertBeneficiaryService
            .getServiceExpertBeneficiaryViewListByCriteria(criteria)
            .then((data) => {
                setServiceExpertBeneficiaryList(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedService, isNew) => {
        loadServiceExpertBeneficiaryList();
    };

    const showNewDialog = () => {
        setSelectedServiceExpertBeneficiary(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = async (_selectedSeb) => {
        ;
        setSelectedServiceExpertBeneficiary(await serviceExpertBeneficiaryService.getServiceExpertBeneficiary(_selectedSeb.id));
        setEditDialogVisible(true);
    };

    const showViewDialog = async (_selectedSeb) => {
        setSelectedServiceExpertBeneficiary(await serviceExpertBeneficiaryService.getServiceExpertBeneficiary(_selectedSeb.id));
        setViewDialogVisible(true);
    };

    const showSupplyDialog = async (_selectedSeb) => {
        setSelectedServiceExpertBeneficiary(await serviceExpertBeneficiaryService.getServiceExpertBeneficiary(_selectedSeb.id));
        setSupplyDialogVisible(true);
    };

    const showDeleteDialog = async (_selectedSeb) => {
        setSelectedServiceExpertBeneficiary(await serviceExpertBeneficiaryService.getServiceExpertBeneficiary(_selectedSeb.id));
        setDeleteDialogVisible(true);
    };

    const deleteServiceExpertBeneficiary = () => {
        serviceExpertBeneficiaryService
            .deleteServiceExpertBeneficiary(selectedServiceExpertBeneficiary.id)
            .then(() => {
                let _serviceExpertBeneficiaryList = serviceExpertBeneficiaryList.filter(
                    (item) => item.id !== selectedServiceExpertBeneficiary.id
                );
                setServiceExpertBeneficiaryList(_serviceExpertBeneficiaryList);
                toast?.current?.show({
                    severity: "success", summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast?.current?.show({ severity: "error", summary: translatedMessage(error), life: 5000, });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedServiceExpertBeneficiary(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("SERVICE_BENEFICIARY_CREATE") &&
                        (
                            <Button label={translatedMessage("serviceExpertBeneficiary.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate} />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            serviceExpertBeneficiaryService
                .getServiceExpertBeneficiaryListByCriteria(criteria)
                .then((data) => {
                    let exportData = []
                    data.items.forEach(item => {
                        let exportItem = ServiceDataTableUtils.computeServiceExpertBeneficiaryExportFields(item, true)

                        exportData.push(exportItem)
                    })

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ServiceDataTableUtils.serviceExpertBeneficiaryListTableHeader(true)}
                sortOrderHeader={ServiceDataTableUtils.serviceExpertBeneficiaryListSortOrderHeader(true)}
                fileName={GeneralUtils.computeExportFileName("serviceExpertBeneficiary.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-service justify-content-end">
                {hasPermission("SERVICE_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showViewDialog(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_SERVICES}
                        />
                    )}
                {hasPermission("SERVICE_BENEFICIARY_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_BENEFICIARY_SERVICES}
                        />
                    )}
                {hasPermission("SERVICE_SUPPLY_VIEW") &&
                    (
                        <Button
                            className="p-button-rounded p-button-info p-button-icon-only m-1"
                            onClick={() => showSupplyDialog(rowData)}
                            tooltip={translatedMessage("serviceSupply.list")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_SERVICE_SUPPLY}
                        >
                            <FontAwesomeIcon icon="fas fa-clipboard-check" />
                        </Button>
                    )}
                {hasPermission("SERVICE_BENEFICIARY_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_BENEFICIARY_SERVICES}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.endDate)
    }

    const beneficiaryBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button
                            text
                            label={rowData.beneficiaryProfileName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiaryId}`)}
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.beneficiaryProfileName
                    }
                </div>
            </>
        );
    };

    const serviceBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('SERVICE_VIEW') &&
                        <Button
                            text
                            label={rowData.serviceName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/service/${rowData.serviceId}`)}
                        />
                    }
                    {!hasPermission('SERVICE_VIEW') &&
                        rowData.serviceName
                    }
                </div>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-service">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className='mb-0'>{props.pageTitle ? props.pageTitle : translatedMessage("serviceExpertBeneficiary.list")}</h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={serviceExpertBeneficiaryList}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        {!props?.service &&
                            <Column
                                field="serviceName"
                                header={translatedMessage("serviceExpertBeneficiary.service")}
                                headerStyle={{ width: "25%", minWidth: "8rem" }}
                                body={serviceBodyTemplate}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        {!props?.expertProfile &&
                            <Column
                                field="expert"
                                header={translatedMessage("serviceExpertBeneficiary.expert")}
                                headerStyle={{ width: "20%", minWidth: "8rem" }}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        {!props?.beneficiary &&
                            <Column
                                field="beneficiaryProfileName"
                                header={translatedMessage("serviceExpertBeneficiary.beneficiary")}
                                headerStyle={{ width: "15%", minWidth: "8rem" }}
                                body={beneficiaryBodyTemplate}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        <Column
                            field="serviceVenueName"
                            header={translatedMessage("service.venue")}
                            headerStyle={{ width: "15%", minWidth: "8rem" }}
                            sortable
                            filter
                            filterElement={(e) => ServiceDataTableUtils.entityLookupFilterTemplate(e, serviceVenues, translatedMessage('service.venue'))}
                            filterHeaderClassName="pcn-filter-column-250"
                            showFilterMenu={false}
                        />
                        <Column
                            field="startDate"
                            header={translatedMessage("generic.interval")}
                            body={dateBodyTemplate}
                            sortable
                            headerStyle={{ width: "5%", minWidth: '8rem' }}
                        />
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <ServiceExpertBeneficiaryViewDialogComponent
                        value={selectedServiceExpertBeneficiary}
                        visible={viewDialogVisible}
                        visibleSetter={setViewDialogVisible}
                    />

                    <ServiceExpertBeneficiaryEditDialog
                        value={selectedServiceExpertBeneficiary}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        serviceVenues={serviceVenues}
                        service={props?.service}
                        beneficiary={props?.beneficiary}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedServiceExpertBeneficiary ? selectedServiceExpertBeneficiary?.beneficiary?.profile?.name : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteServiceExpertBeneficiary()}
                        info="serviceExpertBeneficiary.delete.info"
                    />

                    <ServiceSupplyListDialogComponent
                        visible={supplyDialogVisible}
                        visibleSetter={setSupplyDialogVisible}
                        value={selectedServiceExpertBeneficiary}
                    />
                </>
            )}
        </>
    );
};

export default ServiceExpertBeneficiaryListComponent;
