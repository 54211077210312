import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { ProfileService } from '../../service/ProfileService';
import { classNames } from 'primereact/utils';
import { translatedMessage } from '../../service/LanguageService';
import ProfileAutocompleteFieldComponent from '../profile/ProfileAutocompleteFieldComponent';
import { InputTextarea } from 'primereact/inputtextarea';

let emptyRecord = {
    profile: null,
    name: '',
    description: '',
};

const ProfileCredentialEditDialog = (props) => {
    const [profileCredential, setProfileCredential] = useState(null);
    const [isNew, setIsNew] = useState(true)

    const toast = useRef(null);
    const profileService = new ProfileService();

    useEffect(() => {
        let _profileCredential = props.value ? props.value : emptyRecord;
        if (props?.profile) {
            _profileCredential.profile = props?.profile;
        }
        setProfileCredential(_profileCredential);
        setIsNew(props.value ? false : true);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setProfileCredential(null)
            formik.resetForm();
            props.visibleSetter(false);
        }
    };

    const formik = useFormik({
        initialValues: profileCredential ? profileCredential : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.name || !data.name.trim()) {
                errors.name = translatedMessage("form.error.name.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log("save: ", data)
            profileService.saveProfileCredential(data)
                .then(savedData => {
                    toast?.current?.show({ severity: 'success', summary: translatedMessage("profileCredential.save.success"), life: 3000 });
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, isNew);
                    }
                    hideDialog();
                })
                .catch(error => {
                    toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };


    const handleProfileChange = async (_existingProfile) => {
        let _profileCredential = { ...formik.values, profile: _existingProfile }

        setProfileCredential(_profileCredential)
    }

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="profileCredential-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('profileCredential.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <div className="flex flex-column align-items-center">
                    <div className="col-12">
                        <form id="profileCredential-form" onSubmit={formik.handleSubmit}>
                            <div className='grid'>
                                {!props?.profile &&
                                    < ProfileAutocompleteFieldComponent
                                        profile={formik.values.profile}
                                        setExistingProfile={(e) => handleProfileChange(e)}
                                        entityName="EXPERT"
                                        showAddProfile={true}
                                        validationError={isFormFieldValid('profile')}
                                        title={translatedMessage('profileCredential.profile')}
                                    />}

                                <div className="col-12 md:col-12">
                                    <div className="p-inputgroup">
                                        <span className="p-float-label">
                                            <InputText type="text" name="name" id="name"
                                                value={formik.values.name} onChange={formik.handleChange} autoFocus
                                                className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                                {translatedMessage("profileCredential.name")} *
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('name')}
                                </div>

                                <div className="col-12 md:col-12">
                                    <div className="p-inputgroup">
                                        <span className="p-float-label">
                                            <InputTextarea id="description" name="description" 
                                                value={formik.values.description} onChange={formik.handleChange} rows={3} cols={20} 
                                                className={classNames({ 'p-invalid': isFormFieldValid('description') })} />                                                
                                            <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>
                                                {translatedMessage("profileCredential.description")}
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>);
};

export default ProfileCredentialEditDialog;
