import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import { BeneficiaryService } from '../../service/BeneficiaryService';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import FileUtils from '../../utilities/FileUtils';
import StorageFileDownloadTableButton from '../../components/file/StorageFileDownloadTableButton';

const AnalysisList = () => {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(false);
    const [files, setFiles] = useState(null);
    const [usageLookup, setUsageLookup] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            pin: { value: null, matchMode: FilterMatchMode.CONTAINS },
            "file.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
            "file.issuer": { value: null, matchMode: FilterMatchMode.CONTAINS },
            "file.registrationNumber": { value: null, matchMode: FilterMatchMode.CONTAINS },
            "file.usageId": { value: null, matchMode: FilterMatchMode.IN },
        },
        filterRoleValues: []
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const propToColumnMap = {
        fullName: 'full_name',
        pin: 'pin',
        "file.name": 'name',
        "file.issuer": 'issuer',
        "file.registrationNumber": 'registration_number',
        "file.usageId": 'usage_id',
    };

    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getUsages = async () => {
            const usageLookupResponse = await EnumService.getFileUsageByEntity("ANALYSIS");
            setUsageLookup(usageLookupResponse)
        }

        getUsages()
        setFirstLoading(false);
    }, []);

    useEffect(() => {
        lazyLoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {}
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC"
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id'
        criteria.fullName = lazyParams.filters.fullName.value
        criteria.pin = lazyParams.filters.pin.value
        criteria.fileName = lazyParams.filters["file.name"].value
        criteria.issuer = lazyParams.filters["file.issuer"].value
        criteria.registrationNumber = lazyParams.filters["file.registrationNumber"].value
        criteria.usages = lazyParams.filters["file.usageId"].value

        return criteria
    }

    const loadData = async () => {
        let criteria = setCriteria();

        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await beneficiaryService.getAnalysisFileList(criteria)
            .then((data) => {
                setFiles(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
            })
            .catch((error) => {
                setError(true)
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const lazyLoadData = () => {
        setLoading(true);
        loadData();
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const leftToolbarTemplate = () => {
        return (
            <></>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <></>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button 
                            text 
                            label={rowData.fullName} 
                            className="pcn-button-slim p-button" 
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiaryId}/analysis`)} 
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.fullName
                    }
                </div>
            </>
        );
    };

    const reagistrationBodyTemplate = (rowData) => {
        return (
            <>
                <div className='mb-1'>{rowData.file?.registrationNumber}</div>
                <div>{GeneralUtils.formatDate(rowData.file?.registrationDate)}</div>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {<StorageFileDownloadTableButton fileUuid={rowData.file?.uuid} fileName={rowData.file?.name} />}
                </div>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <div className='w-full text-align-left'>
                                <h5 className="m-0">{translatedMessage("analysis.beneficiaries")}</h5>
                            </div>
                            <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                            <DataTable
                                ref={dt}
                                value={files}
                                lazy
                                dataKey="id"
                                paginator
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                totalRecords={totalRecords}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                                onPage={onPage} onSort={onSort} onFilter={onFilter}
                                loading={loading}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                                filterDisplay="row"
                                filters={lazyParams.filters}
                            >
                                <Column
                                    field="fullName"
                                    header={translatedMessage("generic.name")}
                                    body={(e) => nameBodyTemplate(e)}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '8rem' }}                                    
                                    filter
                                    filterField="fullName"
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="pin"
                                    header={translatedMessage("profile.pin")}
                                    body={(e) => e.pin ? e.pin : "-"}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    filterField='pin'
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="file.name"
                                    header={translatedMessage("generic.nameRo")}
                                    body={(e) => e.file?.name ? e.file.name : "-"}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="file.issuer"
                                    header={translatedMessage("file.issuer")}
                                    body={(e) => e.file?.issuer ? e.file.issuer : "-"}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="file.registrationNumber"
                                    header={translatedMessage("file.registrationNumber")}
                                    body={(e) => reagistrationBodyTemplate(e)}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '8rem' }}                                    
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="file.usageId"
                                    header={translatedMessage("file.usage")}
                                    body={(e) => e.file?.usageLabel ? e.file.usageLabel : "-"}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}                                                                        
                                    filter
                                    filterElement={(e) => FileUtils.usageFilterTemplate(e, usageLookup)}
                                    showFilterMenu={false}
                                />
                                <Column body={actionBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AnalysisList;
