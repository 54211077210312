import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import { BeneficiaryService } from '../../service/BeneficiaryService';
import BeneficiaryEditDialog from './BeneficiaryEditDialog';
import EnumService from '../../service/EnumService';
import BeneficiaryResponsibleDialog from './BeneficiaryResponsibleDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BeneficiaryDepartmentDialog from './BeneficiaryDepartmentDialog';
import BeneficiaryExportDataUtils from '../../utilities/BeneficiaryExportDataUtils';
import GeneralUtils from '../../utilities/GeneralUtils';
import SecurityService from '../../service/SecurityService';

const BeneficiaryList = () => {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState(null);
    const [beneficiary, setBeneficiary] = useState(null);
    const [responsibleType, setResponsibleType] = useState(null);
    const [statuses, setStatuses] = useState([])
    const [genders, setGenders] = useState([])
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showResponsibleDialog, setShowResponsibleDialog] = useState(false);
    const [showDepartmentDialog, setShowDepartmentDialog] = useState(false);

    const [canCreateBeneficiary, setCanCreateBeneficiary] = useState(false);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            registrationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
            'profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'profile.lastName': { value: null, matchMode: FilterMatchMode.CONTAINS },
            status: { value: null, matchMode: FilterMatchMode.IN },
            'profile.pin': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'profile.hasPin': { value: null, matchMode: FilterMatchMode.EQUALS },
            gender: { value: null, matchMode: FilterMatchMode.EQUALS },
            departmentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            responsibleName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            assistantName: { value: null, matchMode: FilterMatchMode.CONTAINS }
        },
        filterRoleValues: []
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const propToColumnMap = {
        registrationNumber: 'registrationNumber',
        'profile.fullName': 'p.first_name, p.last_name',
        'profile.lastName': 'last_name',
        status: 'status',
        'profile.pin': 'pin',
        'profile.hasPin': 'has_pin',
        gender: 'gender',
        departmentName: "departmentName",
        responsibleName: "responsibleName",
        assistantName: "assistantName"
    };

    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getStatuses = async () => {
            setStatuses(await EnumService.getEnumByName('BeneficiaryStatus'));
        };

        const getGenders = async () => {
            setGenders(await EnumService.getEnumByName('Gender'));
        };

        const checkCanCreateBeneficiary = async () => {
            setCanCreateBeneficiary(await SecurityService.check('ADD_BENEFICIARY'));
        };

        getStatuses()
        getGenders()
        checkCanCreateBeneficiary()
        setFirstLoading(false)
    }, []);

    useEffect(() => {
        lazyLoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {}
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC"
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id'
        criteria.registrationNumber = lazyParams.filters.registrationNumber.value
        criteria.fullName = lazyParams.filters['profile.fullName'].value
        criteria.lastName = lazyParams.filters['profile.lastName'].value
        criteria.status = lazyParams.filters.status.value
        criteria.pin = lazyParams.filters['profile.pin'].value
        criteria.hasPin = lazyParams.filters['profile.hasPin'].value
        criteria.gender = lazyParams.filters.gender.value
        criteria.departmentName = lazyParams.filters.departmentName.value
        criteria.responsibleName = lazyParams.filters.responsibleName.value
        criteria.assistantName = lazyParams.filters.assistantName.value

        return criteria
    }

    const loadData = async () => {
        let criteria = setCriteria();

        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await beneficiaryService.getBeneficiaryList(criteria)
            .then((data) => {
                // console.log(data.items);
                setBeneficiaries(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
            })
            .catch((error) => {
                setError(true)
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const lazyLoadData = () => {
        setLoading(true);
        loadData();
    }

    const addNew = () => {
        // setBeneficiary(emptyRecord);        
        setShowEditDialog(true);
    };

    const editData = (_beneficiary) => {
        setBeneficiary({ ..._beneficiary });
        setShowEditDialog(true);
    };

    const editResponsible = (_beneficiary, _type) => {
        setBeneficiary({ ..._beneficiary });
        setResponsibleType(_type)
        setShowResponsibleDialog(true);
    };

    const editDepartment = (_beneficiary) => {
        setBeneficiary({ ..._beneficiary });
        setShowDepartmentDialog(true);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('BENEFICIARY_CREATE') &&
                        <Button
                            label={translatedMessage("beneficiary.newBeneficiary")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                            disabled={!canCreateBeneficiary}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={BeneficiaryExportDataUtils.beneficiaryTableHeader()}
                sortOrderHeader={BeneficiaryExportDataUtils.beneficiarySortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("beneficiary.beneficiaries")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            beneficiaryService.getBeneficiaryList(criteria)
                .then((data) => {
                    let exportData = []
                    data.items.forEach(item => {
                        let exportItem = BeneficiaryExportDataUtils.computeExportFields(item)

                        exportData.push(exportItem)
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const afterSave = async (savedData, isSuccess, error = null) => {
        if (isSuccess) {
            lazyLoadData();
            toast?.current?.show({ severity: 'success', summary: translatedMessage("beneficiary.save.success"), life: 3000 });
        } else {
            error && toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
        }
    }

    const afterResponsibleSave = async (savedData, isSuccess, error = null) => {
        if (isSuccess) {
            lazyLoadData();
            toast?.current?.show({ severity: 'success', summary: translatedMessage("generic.save.success"), life: 3000 });
        } else {
            error && toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
        }
    }

    const afterDepartmentSave = async (savedData, isSuccess, error = null) => {
        if (isSuccess) {
            lazyLoadData();
            toast?.current?.show({ severity: 'success', summary: translatedMessage("generic.save.success"), life: 3000 });
        } else {
            error && toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
        }
    }

    const hideDialog = () => {
        setShowEditDialog(false)
        setShowResponsibleDialog(false)
        setShowDepartmentDialog(false)
        setBeneficiary(null)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => navigate(`/beneficiary/${rowData.id}`)}
                            title={translatedMessage("generic.view")} disabled={!rowData.actions['VIEW_BENEFICIARY']} />
                    }
                    {hasPermission('BENEFICIARY_EDIT') &&
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")} disabled={!rowData.actions['EDIT_BENEFICIARY']} />
                    }
                    {hasPermission('BENEFICIARY_SET_RESPONSIBLE') &&
                        <Button className="p-button-rounded p-button-info p-button-icon-only m-1" onClick={() => editResponsible(rowData, "CASE_MANAGER")}
                            title={translatedMessage("beneficiary.responsible")} disabled={!rowData.actions['SET_BENEFICIARY_RESPONSIBLE']}  >
                            <FontAwesomeIcon icon='fa-solid fa-user-tie' />
                        </Button>
                    }
                    {hasPermission('BENEFICIARY_SET_EXPERT') &&
                        <Button className="p-button-rounded p-button-info p-button-icon-only m-1" onClick={() => editResponsible(rowData, "SOCIAL_ASSISTANT")}
                            title={translatedMessage("beneficiary.socialAssistant")} disabled={!rowData.actions['SET_BENEFICIARY_EXPERT']}  >
                            <FontAwesomeIcon icon='fa-solid fa-user-gear' />
                        </Button>
                    }
                    {hasPermission('BENEFICIARY_SET_EXPERT') &&
                        <Button className="p-button-rounded p-button-info p-button-icon-only m-1" onClick={() => editResponsible(rowData, "PSYCHOLOGIST")}
                            title={translatedMessage("beneficiary.psychologist")} disabled={!rowData.actions['SET_BENEFICIARY_EXPERT']}  >
                            <FontAwesomeIcon icon='fa-solid fa-user' />
                        </Button>
                    }
                    {hasPermission('BENEFICIARY_TRANSFER') &&
                        <Button className="p-button-rounded p-button-info p-button-icon-only m-1" onClick={() => editDepartment(rowData)}
                            title={translatedMessage("beneficiary.transfer")} disabled={!rowData.actions['TRANSFER_BENEFICIARY']} >
                            <FontAwesomeIcon icon='fa-solid fa-share-from-square' />
                        </Button>
                    }
                </div>
            </>
        );
    };

    const statusTemplate = (rowData) => {
        let isActive = 'ACTIVE' === rowData.status;
        return (
            <>
                {hasPermission('BENEFICIARY_EDIT') && (
                    <ToggleButton
                        id={`status_${rowData.id}`}
                        name={`status_${rowData.id}`}
                        checked={isActive}
                        onChange={() => {
                            updateStatus(rowData);
                        }}
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        aria-label={translatedMessage("beneficiary.status")}
                        className="pcn-status-toogle"
                        onLabel={translatedMessage('BeneficiaryStatus.ACTIVE')}
                        offLabel={translatedMessage('BeneficiaryStatus.INACTIVE')}
                        tooltip={translatedMessage(isActive ? 'beneficiary.inactivate' : 'beneficiary.activate')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={!rowData.actions['EDIT_BENEFICIARY']}
                    />
                )}
                {!hasPermission('BENEFICIARY_EDIT') &&
                    <span className={`pill status status-${rowData.status.toString().toLowerCase()}`}>
                        {translatedMessage('BeneficiaryStatus.' + rowData.status)}
                    </span>}
            </>
        );
    };

    const updateStatus = async (_beneficiary) => {
        let oldStatus = _beneficiary.status;
        let newStatus = oldStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

        beneficiaryService.saveBeneficiary({ ..._beneficiary, status: newStatus })
            .then(() => {
                toast?.current?.show({ severity: 'success', summary: translatedMessage('beneficiary.save.success'), life: 3000 });
                lazyLoadData()
            })
            .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const statusRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={statusItemTemplate}
        />
    };

    const statusItemTemplate = (option) => {
        return <span className={`inline-block status status-${option.value.toString().toLowerCase()}`}>{option.label}</span>;
    };

    const genderRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={genders}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={options.label}
        />
    };

    const pinBodyTemplate = (rowData) => {
        return rowData.profile?.pin
            ? rowData.profile?.pin
            : (rowData.hasPin ? "-" : translatedMessage("profile.pin.unidentified"))
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <div>{rowData.profile.fullName}</div>
                <div className='mt-2 aspc-datatable-small-font' title={translatedMessage("beneficiary.registrationNumber")}>
                    {translatedMessage("beneficiary.registrationNumber.short") + ": " + rowData.registrationNumber + " / " + GeneralUtils.formatDate(rowData.registrationDate)}
                </div>
                <div className='mt-1 aspc-datatable-small-font' title={translatedMessage("profile.pin")}>
                    {translatedMessage("profile.pin") + ": " + pinBodyTemplate(rowData)}
                </div>
            </>
        )
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full text-align-left'>
                                <h5 className="m-0">{translatedMessage("beneficiary.beneficiaries")}</h5>
                            </div>
                            <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                            <DataTable
                                ref={dt}
                                value={beneficiaries}
                                lazy
                                dataKey="id"
                                paginator
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                totalRecords={totalRecords}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                                onPage={onPage} onSort={onSort} onFilter={onFilter}
                                loading={loading}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                                filterDisplay="row"
                                filters={lazyParams.filters}
                            >
                                <Column
                                    field="profile.fullName"
                                    header={translatedMessage("generic.name")}
                                    sortable
                                    body={nameBodyTemplate}
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    filterField="profile.fullName"
                                    showFilterMenu={false}
                                />
                                {/* <Column
                                    field="profile.pin"
                                    header={translatedMessage("profile.pin")}
                                    body={pinBodyTemplate}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                                    filter
                                    filterField='profile.pin'
                                    showFilterMenu={false}
                                /> */}
                                <Column
                                    field="gender"
                                    filterField="gender"
                                    header={translatedMessage('profile.gender')}
                                    sortable
                                    headerStyle={{ width: '8%', minWidth: '5rem' }}
                                    body={e => translatedMessage("Gender." + e.profile.gender)}
                                    showFilterMenu={false}
                                    filter
                                    filterElement={genderRowFilterTemplate}
                                />
                                <Column
                                    field="departmentName"
                                    header={translatedMessage("organizationProfile.department")}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    filterField="departmentName"
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="responsibleName"
                                    header={translatedMessage("beneficiary.responsible")}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    filterField="responsibleName"
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="assistantName"
                                    header={translatedMessage("beneficiary.socialAssistant")}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    filterField="assistantName"
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="status"
                                    filterField="status"
                                    header={translatedMessage('generic.status')}
                                    sortable
                                    headerStyle={{ width: '8%', minWidth: '5rem' }}
                                    body={statusTemplate}
                                    showFilterMenu={false}
                                    filter
                                    filterElement={statusRowFilterTemplate}
                                />
                                <Column body={actionBodyTemplate} />
                            </DataTable>

                            {showEditDialog &&
                                <BeneficiaryEditDialog
                                    value={beneficiary}
                                    visible={showEditDialog}
                                    afterSave={afterSave}
                                    visibleSetter={hideDialog}
                                />
                            }

                            {showResponsibleDialog &&
                                <BeneficiaryResponsibleDialog
                                    value={beneficiary}
                                    visible={showResponsibleDialog}
                                    afterSave={afterResponsibleSave}
                                    visibleSetter={hideDialog}
                                    type={responsibleType}
                                />
                            }

                            {showDepartmentDialog &&
                                <BeneficiaryDepartmentDialog
                                    value={beneficiary}
                                    visible={showDepartmentDialog}
                                    afterSave={afterDepartmentSave}
                                    visibleSetter={hideDialog}
                                />
                            }

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BeneficiaryList;
