import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { OrganizationProfileService } from '../../service/OrganizationProfileService';
import { translatedMessage } from "../../service/LanguageService";
import { hasPermission } from '../../service/UserService';
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from '../../service/EnumService';
import DeleteModalComponent from "../DeleteModalComponent";
import OrganizationProfileEditDialog from "./OrganizationProfileEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import OrganizationDataTableUtils from "../../utilities/OrganizationDataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import OrganizationProfileViewDialogComponent from "./OrganizationProfileViewDialogComponent";
import UserCreateForProfileDialogComponent from "../user/UserCreateForProfileDialogComponent";
import { ToggleButton } from "primereact/togglebutton";
import SecurityService from '../../service/SecurityService';

const OrganizationProfileListComponent = (props) => {
    const [organization, setOrganization] = useState(null);
    const [organizationProfileList, setOrganizationProfileList] = useState([]);
    const [selectedOP, setSelectedOP] = useState(null);
    const [positionTypes, setPositionTypes] = useState([])
    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [createUserDialogVisible, setCreateUserDialogVisible] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const [canCreate, setCanCreate] = useState(true);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
            profile: { value: null, matchMode: FilterMatchMode.CONTAINS },
            position: { value: null, matchMode: FilterMatchMode.CONTAINS },
            positionType: { value: null, matchMode: FilterMatchMode.EQUALS },
            email: { value: null, matchMode: FilterMatchMode.CONTAINS },
            phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
            manager: { value: null, matchMode: FilterMatchMode.IN },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        organization: "organization_name",
        profile: "profile_name",
        position: "position",
        email: "email",
        phone: "phone",
        manager: "is_manager",
        positionType: "position_type"
    };

    const toast = useRef(null);
    const dt = useRef(null);

    const organizationProfileService = useMemo(() => new OrganizationProfileService(), []);

    useEffect(() => {
        setFirstLoading(true);
        setOrganization(props?.organization);

        const getPositionTypes = async () => {
            setPositionTypes(await EnumService.getEnumByName('PositionType'));
        };

        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_EXPERT'));
        };

        getPositionTypes()
        checkCanCreate()
        setFirstLoading(false);
    }, [organizationProfileService, props?.organization]);

    useEffect(() => {
        loadOrganizationProfileList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        if (props?.organizationType === 'DEPARTMENT') {
            GeneralUtils.processSessionFilterValues("DepartmentProfileList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);
        } else if (props?.organizationType === 'PROVIDER') {
            GeneralUtils.processSessionFilterValues("ProviderProfileList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);
        } else if (props?.organizationType === 'CENTER') {
            GeneralUtils.processSessionFilterValues("CenterProfileList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);
        }

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";

        criteria.organizationName = lazyParams.filters.organization.value;
        criteria.profileName = lazyParams.filters.profile.value;
        criteria.position = lazyParams.filters.position.value;
        criteria.positionType = lazyParams.filters.positionType.value;
        criteria.email = lazyParams.filters.email.value;
        criteria.phone = lazyParams.filters.phone.value;
        criteria.manager = lazyParams.filters.manager.value;
        criteria.organizationIds = props?.organization?.id ? [props?.organization?.id] : [];

        if (organization && organization.id) {
            criteria.organizationIds = [organization.id];
        }
        return criteria;
    };

    const loadOrganizationProfileList = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await organizationProfileService
            .getOrganizationProfilesByCriteria(criteria)
            .then((data) => {
                setOrganizationProfileList(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async () => {
        loadOrganizationProfileList();
    };

    const showNewDialog = () => {
        setSelectedOP(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (organizationProfile) => {
        setSelectedOP(organizationProfile);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (organizationProfile) => {
        setSelectedOP(organizationProfile);
        setDeleteDialogVisible(true);
    };

    const showViewDialog = (organizationProfile) => {
        setSelectedOP(organizationProfile);
        setViewDialogVisible(true);
    };

    const showCreateUserDialog = (organizationProfile) => {
        setSelectedOP(organizationProfile);
        setCreateUserDialogVisible(true);
    };

    const deleteService = () => {
        organizationProfileService
            .deleteOrganizationProfile(selectedOP.id)
            .then(() => {
                let _services = organizationProfileList.filter(
                    (item) => item.id !== selectedOP.id
                );
                setOrganizationProfileList(_services);
                toast?.current?.show({
                    severity: "success", summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast?.current?.show({ severity: "error", summary: translatedMessage(error), life: 5000, });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedOP(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("ORGANIZATION_PROFILE_CREATE") &&
                        (
                            <Button label={translatedMessage("organizationProfile.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate} />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            organizationProfileService
                .getOrganizationProfilesByCriteria(criteria)
                .then((data) => {
                    let exportData = data.items.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item
                        exportItem.firstName = item.profile.firstName
                        exportItem.lastName = item.profile.lastName
                        exportItem.organization = item.organization.name
                        exportItem.positionType = item.positionType ? translatedMessage('PositionType.' + item.positionType) : ''
                        exportItem.status = translatedMessage('OrganizationProfileStatus.' + item.status)
                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn)
                        delete exportItem.profile

                        return exportItem
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={OrganizationDataTableUtils.departmentProfileListTableHeader()}
                sortOrderHeader={OrganizationDataTableUtils.departmentProfileListSortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("organizationProfile.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-service justify-content-end">
                {hasPermission("ORGANIZATION_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showViewDialog(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_EXPERT}
                        />
                    )}
                {hasPermission("ORGANIZATION_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_EXPERT}
                        />
                    )}
                {hasPermission("ORGANIZATION_EDIT") &&
                    (
                        <Button
                            icon="pi pi-user-plus"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showCreateUserDialog(rowData)}
                            tooltip={translatedMessage("user.create")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={rowData.profile?.user}
                        />
                    )}
                {hasPermission("ORGANIZATION_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_EXPERT}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    const profileBodyTemplate = (rowData) => {
        return (
            <>
                <div className="mb-1">{rowData.profile.fullName}</div>
                <div>{translatedMessage("organizationProfile.email") + ": " + GeneralUtils.dashIfNullForObject(rowData.email)}</div>
                <div>{translatedMessage("organizationProfile.phone") + ": " + GeneralUtils.dashIfNullForObject(rowData.phone)}</div>

            </>
        );
    };

    const positionTypeTemplate = (rowData) => {
        return (
            <span className={`px-1}`}>
                {rowData.positionType ? translatedMessage('PositionType.' + (rowData.positionType)) : ""}
            </span>
        );
    };

    const statusTemplate = (rowData) => {
        let isActive = 'ACTIVE' === rowData.status;
        return (
            <>
                {hasPermission('ORGANIZATION_PROFILE_EDIT') && (
                    <ToggleButton
                        id={`status_${rowData.id}`}
                        name={`status_${rowData.id}`}
                        checked={isActive}
                        onChange={() => {
                            updateStatus(rowData);
                        }}
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        aria-label={translatedMessage("organizationProfile.status")}
                        className="pcn-status-toogle"
                        onLabel={translatedMessage('OrganizationProfileStatus.ACTIVE')}
                        offLabel={translatedMessage('OrganizationProfileStatus.INACTIVE')}
                        tooltip={translatedMessage(isActive ? 'organizationProfile.inactivate' : 'organizationProfile.activate')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={!rowData.actions?.ADD_EXPERT}
                    />
                )}
                {!hasPermission('ORGANIZATION_PROFILE_EDIT') &&
                    <span className={`pill status status-${rowData.status.toString().toLowerCase()}`}>
                        {translatedMessage('OrganizationProfileStatus.' + rowData.status)}
                    </span>}
            </>
        );
    };

    const updateStatus = async (_organizationProfile) => {
        let oldStatus = _organizationProfile.status;
        let newStatus = oldStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

        await organizationProfileService.saveOrganizationProfile({ ..._organizationProfile, status: newStatus })
            .then((savedData) => {
                toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                updateList()
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const positionTypeRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={positionTypes}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={(e) => translatedMessage('PositionType.' + e.value)}
        />
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className='mb-0'>{props.pageTitle ? props.pageTitle : translatedMessage("organizationProfile.list")}</h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={organizationProfileList}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        {!props?.organization &&
                            <Column
                                field="organization"
                                header={translatedMessage("organizationProfile.department")}
                                headerStyle={{ width: '20%', minWidth: "5rem" }}
                                body={(e) => e.organization?.name}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        <Column
                            field="profile"
                            header={translatedMessage("organizationProfile.profile")}
                            headerStyle={{ width: '20%', minWidth: "5rem" }}
                            body={profileBodyTemplate}
                            sortable
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="position"
                            header={translatedMessage("organizationProfile.position")}
                            headerStyle={{ width: '15%', minWidth: "5rem" }}
                            sortable
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="positionType"
                            filterField="positionType"
                            header={translatedMessage('organizationProfile.positionType')}
                            sortable
                            headerStyle={{ width: '15%', minWidth: '5rem' }}
                            body={positionTypeTemplate}
                            showFilterMenu={false}
                            filter
                            filterElement={positionTypeRowFilterTemplate}
                        />
                        {/* <Column
                            field="email"
                            header={translatedMessage("organizationProfile.email")}
                            headerStyle={{ minWidth: "5rem" }}
                            sortable
                            filter
                            showFilterMenu={false}
                        /> */}
                        {/* <Column
                            field="phone"
                            header={translatedMessage("organizationProfile.phone")}
                            headerStyle={{ width: '10%', minWidth: "5rem" }}
                            sortable
                            filter
                            showFilterMenu={false}
                        /> */}
                        <Column
                            field="status"
                            filterField="status"
                            header={translatedMessage('generic.status')}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '5rem' }}
                            body={statusTemplate}
                            showFilterMenu={false}
                            filter
                        // filterElement={statusRowFilterTemplate}
                        />
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <OrganizationProfileEditDialog
                        value={selectedOP}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        organization={props?.organization}
                        positionTypes={positionTypes}
                        organizationType={props?.organizationType}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedOP ? selectedOP.profile?.fullName + ' / ' + selectedOP.organization?.name : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteService()}
                        info="organizationProfile.delete.info"
                    />

                    <OrganizationProfileViewDialogComponent
                        organizationProfile={selectedOP}
                        visible={viewDialogVisible}
                        visibleSetter={(value) => setViewDialogVisible(value)}
                    />

                    <UserCreateForProfileDialogComponent
                        profile={selectedOP?.profile}
                        visible={createUserDialogVisible}
                        afterSave={updateList}
                        visibleSetter={(value) => setCreateUserDialogVisible(value)}
                    />
                </>
            )}
        </>
    );
};

export default OrganizationProfileListComponent;
