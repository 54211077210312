import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../service/LanguageService';
import { AmpService } from '../../service/AmpService';
import AmpToolbar from './AmpToolbar';
import useNavProps from '../../hooks/useNavProps';
import AmpBeneficiaryListComponent from '../../components/amp/AmpBeneficiaryListComponent';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';

const AmpBeneficiaryList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [amp, setAmp] = useState(null);

    const toast = useRef(null);
    const { setNavPropsValue } = useNavProps();

    const ampService = useMemo(() => new AmpService(), []);
    const { ampIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true)

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getAmp = async () => {
            await ampService.getAmp(ampIdParam)
                .then(async (_data) => {
                    setAmp(_data)

                    // await checkEditPermission()
                    setNavPropsValue('amp', _data.id, _data.profile.fullName)

                    setIsLoading(false)
                })
                .catch((error) => {
                    setError(true)
                    toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                })
        }

        getAmp()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ampIdParam, ampService]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <>
                <div className="grid h-full">
                    <Toast ref={toast} />
                    <div className="col-12">
                        <div className="card h-full">
                            <AmpToolbar amp={amp} code="BENEFICIARY" />

                            <AmpBeneficiaryListComponent amp={amp} pageTitle={translatedMessage("beneficiary.beneficiaries")} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default AmpBeneficiaryList;