import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { hasPermission, UserService } from '../../service/UserService';
import { RoleService } from '../../service/RoleService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../../components/ExportButtonComponent';

const sortOrderHeader = ["firstName", "lastName", "username", "status", "createdOn"]

const tableHeader = [
    translatedMessage("generic.firstName"),
    translatedMessage("generic.lastName"),
    translatedMessage("generic.username"),
    translatedMessage("generic.status"),
    translatedMessage("generic.created.on")
]

const Users = () => {
    let emptyProfile = {
        id: null,
        username: '',
        user: { username: '', status: '' },
        firstName: '',
        lastName: '',
        status: '',
        createdOn: ''
    };

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(null);
    const [profile, setProfile] = useState(emptyProfile);
    const [userDialog, setUserDialog] = useState(false);

    const [allRoles, setAllRoles] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [rolesDialog, setRolesDialog] = useState(false);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            'username': { value: '', matchMode: 'contains' },
            'firstName': { value: '', matchMode: 'contains' },
            'lastName': { value: '', matchMode: 'contains' },
            'status': { value: '', matchMode: 'contains' },
            'roleid': { value: '', matchMode: 'contains' }
        },
        filterRoleValues: []
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const statusValues = [
        { label: translatedMessage("generic.none"), value: null },
        { label: translatedMessage("UserStatus.ACTIVE"), value: 'ACTIVE' },
        { label: translatedMessage("UserStatus.INACTIVE"), value: 'INACTIVE' }
    ];

    const userStatuses = [
        { label: translatedMessage("UserStatus.ACTIVE"), value: 'ACTIVE' },
        { label: translatedMessage("UserStatus.INACTIVE"), value: 'INACTIVE' }
    ];    

    const propToColumnMap = {
        'id': 'id',
        'username': 'username',
        'firstName': 'first_name',
        'lastName': 'last_name',
        'status': 'status',
        'createdOn': 'created_on'
    };

    const userService = useMemo(() => new UserService(), []);
    const roleService = useMemo(() => new RoleService(), []);

    useEffect(() => {
        lazyLoadUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    useEffect(() => {
        const getRoles = async () => {
            roleService.getRoles()
                .then((_roles) => {
                    setAllRoles(_roles.map((role) => { return { 'label': role.name, 'value': role.id } }));
                    lazyParams.filterRoleValues = [{ label: translatedMessage("generic.none"), value: null }].concat(_roles.map((role) => { return { 'label': role.name, 'value': role.id } }));
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        roleService.getRoles().then((data) => {
            setAllRoles(data.map((role) => { return { 'label': role.name, 'value': role.id } }));
            lazyParams.filterRoleValues = [{ label: translatedMessage("generic.none"), value: null }].concat(data.map((role) => { return { 'label': role.name, 'value': role.id } }));
        });

        getRoles()
    }, [lazyParams, roleService, userService]);

    const setCriteria = () => {
        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField];
        criteria.username = lazyParams.filters.username.value.length > 0 ? lazyParams.filters.username.value : null;
        criteria.firstName = lazyParams.filters.firstName.value.length > 0 ? lazyParams.filters.firstName.value : null;
        criteria.lastName = lazyParams.filters.lastName.value.length > 0 ? lazyParams.filters.lastName.value : null;
        criteria.status = lazyParams.filters.status.value.length > 0 ? lazyParams.filters.status.value : null;
        criteria.roleId = lazyParams.filters.roleid.value || lazyParams.filters.roleid.value.length > 0 ? lazyParams.filters.roleid.value : null;

        return criteria
    }

    const loadUsers = async () => {
        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;
        await userService.getUsers(criteria)
            .then((data) => {
                setUsers(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
            })
            .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    }

    const lazyLoadUsers = () => {
        setLoading(true);
        loadUsers();
    }

    const openNew = () => {
        setProfile(emptyProfile);
        setUserDialog(true);
    };

    const hideDialog = () => {
        setUserDialog(false);
        setProfile(emptyProfile)
    };

    const hideUserRolesDialog = () => {
        setRolesDialog(false);
    };

    const editProfile = (_profile) => {
        setProfile({ ..._profile });
        setUserDialog(true);
    };

    const editUserRoles = (profile) => {
        setProfile({ ...profile });
        roleService.getUserRoles(profile.user).then((data) => setUserRoles(data.map((role) => role.id)));
        setRolesDialog(true);
    }

    const saveUserRoles = () => {
        roleService.saveUserRoles(profile?.user, userRoles)
            .then(() => {
                toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('user.roles.updated.success.success'), life: 3000 });
            },
                () => {
                    toast?.current?.show({ severity: 'error', summary: translatedMessage('generic.error'), detail: translatedMessage('user.roles.updated.success.error'), life: 3000 });
                });
        setRolesDialog(false);
    }

    const onFilterInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _lazyParams = { ...lazyParams };
        _lazyParams.filters[`${name}`].value = val;

        setLazyParams(_lazyParams);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('USER_CREATE') && <Button label={translatedMessage("user.newUser")} icon="pi pi-plus" className="p-button-primary mr-2" onClick={openNew} />}
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={translatedMessage("menu.administration.users")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            userService.getUsers(criteria)
                .then((data) => {
                    let exportData = data.items.map(item => ({ ...item }));
                    exportData.map(item => {
                        let exportItem = item
                        exportItem.createdOn = new Date(Date.parse(item.createdOn))
                        delete exportItem.fullName
                        delete exportItem.verified
                        delete exportItem.language

                        return exportItem;
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const userStatusBodyTemplate = (status) => {
        return (
            <><span className={`status status-${status.toLowerCase()}`}>{translatedMessage("UserStatus." + status)}</span></>
        );
    };

    const createdOnBodyTemplate = (rowData) => {
        return DataTableUtils.dateTemplate(new Date(Date.parse(rowData.createdOn)))
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('USER_EDIT') && <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => editProfile(rowData)} />}
                {hasPermission('USER_EDIT') && <Button icon="pi pi-cog" className="p-button-rounded p-button-info m-1" onClick={() => editUserRoles(rowData)} />}
            </div>
        );
    };

    const userDialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage("generic.save")} icon="pi pi-check" className="p-button-text" form="user-form" type="submit" />
        </>
    );

    const userRolesDialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={hideUserRolesDialog} />
            <Button label={translatedMessage("generic.save")} icon="pi pi-check" className="p-button-text" onClick={saveUserRoles} />
        </>
    );

    const listBoxItemTemplate = (option) => {
        return (
            <div className="p-multiselect-item">
                <Checkbox checked={userRoles.indexOf(option.value) !== -1} style={{ marginRight: '0.5em' }}></Checkbox>
                <div>{option.label}</div>
            </div>
        );
    }

    const filterTemplate = (
        <Accordion className="pcn-table-filer mb-4">
            <AccordionTab header={<><i className="pi pi-search"></i><span> {translatedMessage("generic.filter.label")}</span></>}>
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-4 xl:col">
                        <span className="p-float-label">
                            <InputText id="filterFirstName" type="search" value={lazyParams.filters.firstName.value} onInput={(e) => onFilterInputChange(e, 'firstName')} />
                            <label htmlFor="filterFirstName">{translatedMessage("generic.firstName")}</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-4 xl:col">
                        <span className="p-float-label">
                            <InputText id="filterLastName" type="search" value={lazyParams.filters.lastName.value} onInput={(e) => onFilterInputChange(e, 'lastName')} />
                            <label htmlFor="filterLastName">{translatedMessage("generic.lastName")}</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-4 xl:col">
                        <span className="p-float-label">
                            <InputText id="filterUsername" type="search" value={lazyParams.filters.username.value} onInput={(e) => onFilterInputChange(e, 'username')} />
                            <label htmlFor="filterUsername">{translatedMessage("generic.username")}</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-4 xl:col">
                        <span className="p-float-label">
                            <Dropdown id="filterStatus" value={lazyParams.filters.status.value} onChange={(e) => onFilterInputChange(e, 'status')} options={statusValues}></Dropdown>
                            <label htmlFor="filterStatus">{translatedMessage("generic.status")}</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-4 xl:col">
                        <span className="p-float-label">
                            <Dropdown id="filterRole" type="search" value={lazyParams.filters.roleid.value} onChange={(e) => onFilterInputChange(e, 'roleid')} options={lazyParams.filterRoleValues}></Dropdown>
                            <label htmlFor="filterRole">{translatedMessage("generic.role")}</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: profile,
        validate: (data) => {
            let errors = {};

            if (!data.user.username) {
                errors.username = translatedMessage("form.error.username.required");
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9._-]+\.[A-Z]{2,4}$/i.test(data.user.username)) {
                errors.username = translatedMessage("form.error.username.invalid");
            }
            if (!data.firstName) {
                errors.firstName = translatedMessage("form.error.firstName.required");
            }
            if (!data.lastName) {
                errors.lastName = translatedMessage("form.error.lastName.required");
            }
            if (data.id && !data.status) {
                errors.status = translatedMessage("form.error.status.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            if (data.id) {
                // update editted user

                userService.updateUser(data)
                    .then(() => {
                        lazyLoadUsers();
                        toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('generic.save.success'), life: 3000 });
                    }, (error) => {
                        let errorMessage = "generic.save.error";
                        if (error && error.response && error.response.data) {
                            errorMessage = error.response.data.message
                        }
                        toast?.current?.show({ severity: 'error', summary: translatedMessage('generic.error'), detail: translatedMessage(errorMessage), life: 3000 });
                    });
            } else {
                // save the new user
                userService.registerUserByAdmin(data)
                    .then(() => {
                        lazyLoadUsers();
                        toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('generic.save.success'), life: 3000 });
                    }, (error) => {
                        let errorMessage = "generic.save.error";
                        if (error && error.response && error.response.data) {
                            errorMessage = error.response.data.message
                        }
                        toast?.current?.show({ severity: 'error', summary: translatedMessage('generic.error'), detail: translatedMessage(errorMessage), life: 3000 });
                    });
            }

            setUserDialog(false);
            setProfile(emptyProfile);
            formik.resetForm();
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleUserChange = (e) => {
        formik.setFieldValue("username", e.target?.value)
        formik.setFieldValue("user.username", e.target?.value)
    }

    const handleStatusChange = (e) => {
        formik.setFieldValue("status", e.target?.value)
        formik.setFieldValue("user.status", e.target?.value)
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{translatedMessage("menu.administration.users")}</h5>
                    </div>
                    <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
                    {filterTemplate}

                    <DataTable
                        ref={dt}
                        value={users}
                        lazy
                        dataKey="id"
                        paginator
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onPage={onPage} onSort={onSort} onFilter={onFilter} loading={loading}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                    >
                        <Column
                            field="user.id"
                            header={translatedMessage("generic.id")}
                            sortable
                            headerStyle={{ width: '3%', minWidth: '3rem' }}
                        />
                        <Column
                            field="lastName"
                            header={translatedMessage("generic.lastName")}
                            sortable
                            headerStyle={{ width: '20%', minWidth: '8rem' }}
                        />                        
                        <Column
                            field="firstName"
                            header={translatedMessage("generic.firstName")}
                            sortable
                            headerStyle={{ width: '20%', minWidth: '8rem' }}
                        />
                        <Column
                            field="user.username"
                            header={translatedMessage("generic.username")}
                            sortable
                            headerStyle={{ width: '25%', minWidth: '10rem' }}
                        />
                        <Column
                            field="user.status"
                            header={translatedMessage("generic.status")}
                            body={(e) => userStatusBodyTemplate(e.user.status)}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '6rem' }}
                        />
                        <Column
                            field="createdOn"
                            header={translatedMessage("generic.created.on")}
                            sortable
                            body={createdOnBodyTemplate}
                            headerStyle={{ width: '5%', minWidth: '8rem' }}
                        />
                        <Column body={actionBodyTemplate} />
                    </DataTable>

                    <Dialog visible={userDialog} style={{ width: '450px' }} header={translatedMessage("user.details")} modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                        <form id="user-form" onSubmit={formik.handleSubmit}>
                            <div className="field">
                                <label htmlFor="username">{translatedMessage("generic.username")} *</label>
                                <InputText type="text" name="username" id="username"
                                    value={formik.values.username} onChange={handleUserChange} autoFocus
                                    className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
                                {getFormErrorMessage('username')}
                            </div>
                            <div className="field">
                                <label htmlFor="lastName">{translatedMessage("generic.lastName")} *</label>
                                <InputText type="text" name="lastName" id="lastName"
                                    value={formik.values.lastName} onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} />
                                {getFormErrorMessage('lastName')}
                            </div>                            
                            <div className="field">
                                <label htmlFor="firstName">{translatedMessage("generic.firstName")} *</label>
                                <InputText type="text" name="firstName" id="firstName"
                                    value={formik.values.firstName} onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('firstName') })} />
                                {getFormErrorMessage('firstName')}
                            </div>
                            {formik.values.id &&
                                <div className="field">
                                    <label htmlFor="status">{translatedMessage("generic.status")} *</label>
                                    <Dropdown id="status" name="status" value={formik.values.status}
                                        options={userStatuses} onChange={handleStatusChange} />
                                    {getFormErrorMessage('status')}
                                </div>
                            }
                        </form>
                    </Dialog>

                    <Dialog visible={rolesDialog} style={{ width: '400px' }} header={`${translatedMessage("user.roles.for")} ${profile.firstName} ${profile.lastName}`} modal className="p-fluid" footer={userRolesDialogFooter} onHide={hideUserRolesDialog}>
                        <ListBox value={userRoles} options={allRoles} onChange={(e) => setUserRoles(e.value)} multiple itemTemplate={listBoxItemTemplate} style={{ width: '25rem' }} listStyle={{ maxHeight: '350px' }} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Users;
