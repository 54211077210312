import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { hasPermission } from '../../service/UserService';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { DepartmentService } from '../../service/DepartmentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DepartmentEditDialog from "../../components/department/DepartmentEditDialog";
import DepartmentToolbar from './DepartmentToolbar';
import DataTableUtils from "../../utilities/DataTableUtils";
import EnumService from "../../service/EnumService";
import GeneralUtils from '../../utilities/GeneralUtils';

const DepartmentView = () => {
    const [department, setDepartment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [departmentStatuses, setDepartmentStatuses] = useState([]);

    const toast = useRef(null);

    const departmentService = useMemo(() => new DepartmentService(), []);

    const { departmentIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getDepartmentStatuses = async () => {
            setDepartmentStatuses(await EnumService.getEnumByName("OrganizationStatus"));
        };

        const getDepartment = async () => {
            await departmentService.getDepartment(departmentIdParam)
                .then(async (_data) => {
                    setDepartment(_data);
                    setNavPropsValue('department', _data.id, _data.name);
                    setIsLoading(false);
                    getDepartmentStatuses();
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getDepartment();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentService, departmentIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <DepartmentToolbar department={department} code="DETAIL" />

                                <div className='w-full grid pcn-data-view-panel mb-5'>
                                    <div className='col-12 flex align-items-center'>
                                        <span className='entity-view-subtitle mr-2'>{translatedMessage("department.details")}</span>
                                        <Button
                                            text
                                            className="pcn-button-slim p-button p-button-icon-only"
                                            onClick={() => setShowEditDialog(true)}
                                            visible={hasPermission('ORGANIZATION_EDIT')}
                                            disabled={!department?.actions?.ADD_ORGANIZATION}
                                        >
                                            <div className='flex align-items-center'>
                                                <FontAwesomeIcon icon='fa-solid fa-pencil' />
                                            </div>
                                        </Button>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('generic.nameRo')}</div>
                                        <div className='filed-value'>{department.name}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('department.parent')}</div>
                                        <div className='filed-value'>{GeneralUtils.dashIfNullForObject(department.parent?.name)}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('department.cif')}</div>
                                        <div className='filed-value'>{department.cif}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('department.isPublic')}</div>
                                        <div className='filed-value'>{DataTableUtils.yesNoTemplate(department.public)}</div>
                                    </div>                                    
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('generic.county')}</div>
                                        <div className='filed-value'>{department.address?.county?.label}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('generic.status')}</div>
                                        <div className='filed-value'>
                                            <span className={`pill status status-${department.status.toLowerCase()}`}>{translatedMessage("OrganizationStatus." + department.status)}</span>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('generic.description')}</div>
                                        <div className='filed-value'>{department.description}</div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <DepartmentEditDialog
                        value={department}
                        visible={showEditDialog}
                        afterSave={(savedData) => setDepartment(savedData)}
                        visibleSetter={() => setShowEditDialog(false)}
                        statuses={departmentStatuses}
                    />
                </>
            )}
        </>
    );
};

export default DepartmentView;
