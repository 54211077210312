import React, { useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { Route, Routes, useLocation } from "react-router-dom";

import MenuService from "./service/MenuService";
import LayoutService from "./service/LayoutService";
import ApiService from "./service/ApiService";

import PersistLogin from "./components/security/PersistLogin";
import RequireAuth from "./components/security/RequireAuth";

import useMenu from "./hooks/useMenu";

import AppTopbar from "./AppTopbar";
import AppBreadcrumb from "./AppBreadcrumb";
import AppFooter from "./AppFooter";
import AppMenu from "./AppMenu";
import AppConfig from "./AppConfig";
import AppRightMenu from "./AppRightMenu";

import Users from "./pages/security/Users";
import Roles from "./pages/security/Roles";
import NotFound from "./pages/NotFound";

import MyProfile from "./pages/myProfile/MyProfile";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import "./assets/css/aspc.css";

import { addLocale, locale } from "primereact/api";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Unauthorized from "./pages/Unauthorized";

import ChangePassword from "./pages/myProfile/ChangePassword";
import HomePage from "./pages/HomePage";

import GeneralUtils from "./utilities/GeneralUtils";
import LanguageService from "./service/LanguageService";
import MyNotifications from "./pages/MyNotifications";

import ProviderListPage from "./pages/provider/ProviderListPage";
import ProviderView from "./pages/provider/ProviderView";

import DepartmentListPage from "./pages/department/DepartmentListPage";
import DepartmentView from "./pages/department/DepartmentView";

import DepartmentProfilesView from "./pages/department/DepartmentProfilesView";
import ProviderServicesView from "./pages/provider/ProviderServicesView";
import OrganizationProfileListPage from "./pages/organizationProfile/OrganizationProfileListPage";
import CenterBeneficiaryListPage from "./pages/centerBeneficiary/CenterBeneficiaryListPage";
import CenterListPage from "./pages/centers/CenterListPage";
import CenterView from "./pages/centers/CenterView";
import CenterBeneficiariesView from "./pages/centers/CenterBeneficiariesView";
import CenterServicesView from "./pages/centers/CenterServicesView";
import EntityLookupList from "./pages/entityLookup/EntityLookupList";
import ServiceListPage from "./pages/services/ServiceListPage";
import ServiceExpertBeneficiaryListPage from "./pages/services/ServiceExpertBeneficiaryListPage";
import ServiceSupplyListPage from "./pages/services/ServiceSupplyListPage";
import BeneficiaryList from "./pages/beneficiary/BeneficiaryList";
import BeneficiaryView from "./pages/beneficiary/BeneficiaryView";
import BeneficiaryProfileExtendedView from "./pages/beneficiary/BeneficiaryProfileExtendedView";
import BeneficiaryFileList from "./pages/beneficiary/BeneficiaryFileList";
import BeneficiaryAnalysis from "./pages/beneficiary/BeneficiaryAnalysis";
import AnalysisList from "./pages/analysis/AnalysisList";
import LegalRepList from "./pages/LegalRepresentative/LegalRepList";
import LegalRepView from "./pages/LegalRepresentative/LegalRepView";
import LegalRepFileList from "./pages/LegalRepresentative/LegalRepFileList";
import BeneficiaryLegalRepList from "./pages/beneficiary/BeneficiaryLegalRepList";
import AmpList from "./pages/amp/AmpList";
import AmpView from "./pages/amp/AmpView";
import RelatedProfileList from "./pages/profile/RelatedProfileList";
import NoticeList from "./pages/notice/NoticeList";
import NoticeView from "./pages/notice/NoticeView";
import NoticeFileList from "./pages/notice/NoticeFileList";
import SolutionList from "./pages/solution/SolutionList";
import SolutionView from "./pages/solution/SolutionView";
import SolutionFileList from "./pages/solution/SolutionFileList";
import MonitoringList from "./pages/monitoring/MonitoringList";
import MonitoringView from "./pages/monitoring/MonitoringView";
import MonitoringFileList from "./pages/monitoring/MonitoringFileList";
import MonitoringHierarchyList from "./pages/monitoring/MonitoringHierarchyList";
import ProfileList from "./pages/profile/ProfileList";
import ProfileView from "./pages/profile/ProfileView";
import ProfileExtendedView from "./pages/profile/ProfileExtendedView";
import ProfileFileList from "./pages/profile/ProfileFileList";
import ProfileCredentialList from "./pages/profile/ProfileCredentialList";
import HearingList from "./pages/hearing/HearingList";
import HearingView from "./pages/hearing/HearingView";
import HearingFileList from "./pages/hearing/HearingFileList";
import AmpProfileExtendedView from "./pages/amp/AmpProfileExtendedView";
import AmpFileList from "./pages/amp/AmpFileList";
import AmpBeneficiaryList from "./pages/amp/AmpBeneficiaryList";
import ProviderProfilesView from "./pages/provider/ProviderProfilesView";
import DepartmentCentersView from "./pages/department/DepartmentCentersView";
import CenterProfilesView from "./pages/centers/CenterProfilesView";
import DepartmentServicesView from "./pages/department/DepartmentServicesView";
import ServiceView from "./pages/services/ServiceView";
import ServiceBeneficiariesView from "./pages/services/ServiceBeneficiariesView";
import BeneficiaryCenterList from "./pages/beneficiary/BeneficiaryCenterList";
import BeneficiaryServiceList from "./pages/beneficiary/BeneficiaryServiceList";
import FamilyPlacementList from "./pages/familyPlacement/FamilyPlacementList";
import FamilyPlacementView from "./pages/familyPlacement/FamilyPlacementView";
import FamilyPlacementProfileExtendedView from "./pages/familyPlacement/FamilyPlacementProfileExtendedView";
import FamilyPlacementFileList from "./pages/familyPlacement/FamilyPlacementFileList";
import FamilyPlacementBeneficiaryList from "./pages/familyPlacement/FamilyPlacementBeneficiaryList";
import BeneficiaryFamilyPlacementList from "./pages/beneficiary/BeneficiaryFamilyPlacementList";
import BeneficiaryAmptList from "./pages/beneficiary/BeneficiaryAmptList";
import BeneficiaryNoticetList from "./pages/beneficiary/BeneficiaryNoticetList";
import BeneficiarySolutiontList from "./pages/beneficiary/BeneficiarySolutiontList";
import BeneficiaryMonitoringtList from "./pages/beneficiary/BeneficiaryMonitoringtList";
import MyServices from "./pages/myProfile/MyServices";
import MyServicesSupply from "./pages/myProfile/MyServicesSupply";
import CenterFileList from "./pages/centers/CenterFileList";

export const RTLContext = React.createContext();

const App = () => {
  const [menuMode, setMenuMode] = useState("static");
  const [inlineMenuPosition, setInlineMenuPosition] = useState("bottom");
  const [desktopMenuActive, setDesktopMenuActive] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [colorMode, setColorMode] = useState("light");
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [inputStyle, setInputStyle] = useState(
    LayoutService.getDefaulltInputStyle()
  );
  const [isRTL, setRTL] = useState(false);
  const [ripple, setRipple] = useState(true);
  const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
  const [menuTheme, setMenuTheme] = useState(
    LayoutService.getDefaultMenuTheme()
  );
  const [topbarTheme, setTopbarTheme] = useState("blue");
  const [theme, setTheme] = useState("indigo");
  const [isInputBackgroundChanged, setIsInputBackgroundChanged] =
    useState(false);
  const [inlineMenuActive, setInlineMenuActive] = useState({});
  const [newThemeLoaded, setNewThemeLoaded] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const copyTooltipRef = useRef();
  let currentInlineMenuKey = useRef(null);
  const location = useLocation();

  PrimeReact.ripple = true;

  let searchClick;
  let topbarItemClick;
  let menuClick;
  let inlineMenuClick;

  const menu = useMenu();

  const routes = MenuService.getRoutes();

  library.add(fas, far, fab);

  ApiService.setRedirectUrl(useLocation().pathname);

  addLocale("ro", GeneralUtils.getCalendarRO());
  locale(LanguageService.getCurrentLanguage());

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    if (menuMode === "overlay") {
      hideOverlayMenu();
    }
    if (menuMode === "static") {
      setDesktopMenuActive(true);
    }
  }, [menuMode]);

  useEffect(() => {
    onColorModeChange(colorMode);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMenuThemeChange = (theme) => {
    setMenuTheme(theme);
  };

  const onTopbarThemeChange = (theme) => {
    setTopbarTheme(theme);
  };

  useEffect(() => {
    const appLogoLink = document.getElementById("app-logo");

    if (
      topbarTheme === "white" ||
      topbarTheme === "yellow" ||
      topbarTheme === "amber" ||
      topbarTheme === "orange" ||
      topbarTheme === "lime"
    ) {
      appLogoLink.src = "assets/layout/images/logo-dark.png";
    } else {
      appLogoLink.src = "assets/layout/images/logo.png";
    }
  }, [topbarTheme]);

  const onThemeChange = (theme) => {
    setTheme(theme);
    const themeLink = document.getElementById("theme-css");
    const themeHref = "assets/theme/" + theme + "/theme-" + colorMode + ".css";
    replaceLink(themeLink, themeHref);
  };

  const onColorModeChange = (mode) => {
    setColorMode(mode);
    setIsInputBackgroundChanged(true);

    if (isInputBackgroundChanged) {
      if (mode === "dark") {
        setInputStyle("filled");
      } else {
        setInputStyle("outlined");
      }
    }

    if (mode === "dark") {
      setMenuTheme("dark");
      setTopbarTheme("dark");
    } else {
      setMenuTheme(LayoutService.getDefaultMenuTheme());
      setTopbarTheme("blue");
    }

    const layoutLink = document.getElementById("layout-css");
    const layoutHref = "assets/layout/css/layout-" + mode + ".css";
    replaceLink(layoutLink, layoutHref);

    const themeLink = document.getElementById("theme-css");
    const urlTokens = themeLink.getAttribute("href").split("/");
    urlTokens[urlTokens.length - 1] = "theme-" + mode + ".css";
    const newURL = urlTokens.join("/");

    replaceLink(themeLink, newURL, () => {
      setNewThemeLoaded(true);
    });
  };

  const replaceLink = (linkElement, href, callback) => {
    if (isIE()) {
      linkElement.setAttribute("href", href);

      if (callback) {
        callback();
      }
    } else {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        const _linkElement = document.getElementById(id);
        _linkElement && _linkElement.remove();
        cloneLinkElement.setAttribute("id", id);

        if (callback) {
          callback();
        }
      });
    }
  };

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onInlineMenuPositionChange = (mode) => {
    setInlineMenuPosition(mode);
  };

  const onMenuModeChange = (mode) => {
    setMenuMode(mode);
  };

  const onRTLChange = () => {
    setRTL((prevState) => !prevState);
  };

  const onMenuClick = (event) => {
    menuClick = true;
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
    else setMobileMenuActive((prevState) => !prevState);

    event.preventDefault();
  };

  const onTopbarItemClick = (event) => {
    topbarItemClick = true;
    if (activeTopbarItem === event.item) setActiveTopbarItem(null);
    else {
      setActiveTopbarItem(event.item);
    }

    event.originalEvent.preventDefault();
  };

  const onSearch = (event) => {
    searchClick = true;
    setSearchActive(event);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items && (menuMode === "overlay" || !isDesktop())) {
      hideOverlayMenu();
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = (event) => {
    setMenuActive((prevState) => !prevState);
  };

  const onRightMenuButtonClick = () => {
    setRightMenuActive((prevState) => !prevState);
  };

  const onMobileTopbarButtonClick = (event) => {
    setMobileTopbarActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onDocumentClick = (event) => {
    if (!searchClick && event.target.localName !== "input") {
      setSearchActive(false);
    }

    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }

    if (!menuClick && (menuMode === "overlay" || !isDesktop())) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }
      hideOverlayMenu();
    }

    if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
      let menuKeys = { ...inlineMenuActive };
      menuKeys[currentInlineMenuKey.current] = false;
      setInlineMenuActive(menuKeys);
    }

    if (!menuClick && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }

    searchClick = false;
    topbarItemClick = false;
    inlineMenuClick = false;
    menuClick = false;
  };

  const hideOverlayMenu = () => {
    setMobileMenuActive(false);
    setDesktopMenuActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  // const onInlineMenuClick = (e, key) => {
  //     let menuKeys = { ...inlineMenuActive };
  //     if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
  //         menuKeys[currentInlineMenuKey.current] = false;
  //     }

  //     menuKeys[key] = !menuKeys[key];
  //     setInlineMenuActive(menuKeys);
  //     currentInlineMenuKey.current = key;
  //     inlineMenuClick = true;
  // };

  const layoutContainerClassName = classNames(
    "layout-wrapper ",
    "layout-menu-" + menuTheme + " layout-topbar-" + topbarTheme,
    {
      "layout-menu-static": menuMode === "static",
      "layout-menu-overlay": menuMode === "overlay",
      "layout-menu-slim": menuMode === "slim",
      "layout-menu-horizontal": menuMode === "horizontal",
      "layout-menu-active": desktopMenuActive,
      "layout-menu-mobile-active": mobileMenuActive,
      "layout-topbar-mobile-active": mobileTopbarActive,
      "layout-rightmenu-active": rightMenuActive,
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple,
      "layout-rtl": isRTL,
    }
  );

  return (
    <RTLContext.Provider value={isRTL}>
      <div className={layoutContainerClassName} onClick={onDocumentClick}>
        <Tooltip
          ref={copyTooltipRef}
          target=".block-action-copy"
          position="bottom"
          content="Copied to clipboard"
          event="focus"
        />

        <AppTopbar
          horizontal={isHorizontal()}
          activeTopbarItem={activeTopbarItem}
          onMenuButtonClick={onMenuButtonClick}
          onTopbarItemClick={onTopbarItemClick}
          onRightMenuButtonClick={onRightMenuButtonClick}
          onMobileTopbarButtonClick={onMobileTopbarButtonClick}
          mobileTopbarActive={mobileTopbarActive}
          searchActive={searchActive}
          onSearch={onSearch}
        />

        <div className="menu-wrapper" onClick={onMenuClick}>
          <div className="layout-menu-container">
            <AppMenu
              model={menu}
              onMenuItemClick={onMenuItemClick}
              onRootMenuItemClick={onRootMenuItemClick}
              menuMode={menuMode}
              active={menuActive}
            />
          </div>
        </div>

        <div className="layout-main">
          <AppBreadcrumb routes={routes} />

          <div className="layout-content">
            <Routes>
              <Route element={<PersistLogin />}>
                <Route path="/home" element={<HomePage />} />

                {/* generic - all users */}
                <Route path="/notifications" element={<MyNotifications />} />

                {/* My Profile menu */}
                <Route path="/my-profile" element={<MyProfile />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/my-services" element={<MyServices />} />
                <Route path="/my-services-supply" element={<MyServicesSupply />} />

                {/* Administration menu */}
                <Route element={<RequireAuth allowedPermissions={["USER_LIST"]} />}>
                  <Route path="/administration/users" element={<Users />} />
                </Route>
                <Route element={<RequireAuth allowedPermissions={["ROLE_LIST"]} />}>
                  <Route path="/administration/roles" element={<Roles />} />
                </Route>
                {/* EntityLookup  menu */}
                <Route path="/administration/entityLookup" element={<EntityLookupList />} />


                {/* Beneficiary menu */}
                <Route element={<RequireAuth allowedPermissions={["BENEFICIARY_LIST"]} />}>
                  <Route path="/beneficiary" element={<BeneficiaryList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam" element={<BeneficiaryView />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/profile-extended" element={<BeneficiaryProfileExtendedView />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/legal-rep" element={<BeneficiaryLegalRepList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/file" element={<BeneficiaryFileList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/analysis" element={<BeneficiaryAnalysis />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/related" element={<RelatedProfileList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/center" element={<BeneficiaryCenterList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/service" element={<BeneficiaryServiceList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/family-placement" element={<BeneficiaryFamilyPlacementList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/amp" element={<BeneficiaryAmptList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/notice" element={<BeneficiaryNoticetList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/solution" element={<BeneficiarySolutiontList />} />
                  <Route path="/beneficiary/:beneficiaryIdParam/monitoring" element={<BeneficiaryMonitoringtList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["ANALYSIS_LIST"]} />}>
                  <Route path="/analysis" element={<AnalysisList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["LEGAL_REPRESENTATIVE_LIST"]} />}>
                  <Route path="/legal-rep" element={<LegalRepList />} />
                  <Route path="/legal-rep/:legalRepIdParam" element={<LegalRepView />} />
                  <Route path="/legal-rep/:legalRepIdParam/file" element={<LegalRepFileList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["NOTICE_LIST"]} />}>
                  <Route path="/notice" element={<NoticeList />} />
                  <Route path="/notice/:noticeIdParam" element={<NoticeView />} />
                  <Route path="/notice/:noticeIdParam/file" element={<NoticeFileList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["HEARING_LIST"]} />}>
                  <Route path="/hearing" element={<HearingList />} />
                  <Route path="/hearing/:hearingIdParam" element={<HearingView />} />
                  <Route path="/hearing/:hearingIdParam/file" element={<HearingFileList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["SOLUTION_LIST"]} />}>
                  <Route path="/solution" element={<SolutionList />} />
                  <Route path="/solution/:solutionIdParam" element={<SolutionView />} />
                  <Route path="/solution/:solutionIdParam/file" element={<SolutionFileList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["MONITORING_LIST"]} />}>
                  <Route path="/monitoring" element={<MonitoringList />} />
                  <Route path="/monitoring/:monitoringIdParam" element={<MonitoringView />} />
                  <Route path="/monitoring/:monitoringIdParam/file" element={<MonitoringFileList />} />
                  <Route path="/monitoring/:monitoringIdParam/hierarchy" element={<MonitoringHierarchyList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["AMP_LIST"]} />}>
                  <Route path="/amp" element={<AmpList />} />
                  <Route path="/amp/:ampIdParam" element={<AmpView />} />
                  <Route path="/amp/:ampIdParam/profile-extended" element={<AmpProfileExtendedView />} />
                  <Route path="/amp/:ampIdParam/file" element={<AmpFileList />} />
                  <Route path="/amp/:ampIdParam/beneficiary" element={<AmpBeneficiaryList />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["FAMILY_PLACEMENT_LIST"]} />}>
                  <Route path="/family-placement" element={<FamilyPlacementList />} />
                  <Route path="/family-placement/:fpIdParam" element={<FamilyPlacementView />} />
                  <Route path="/family-placement/:fpIdParam/profile-extended" element={<FamilyPlacementProfileExtendedView />} />
                  <Route path="/family-placement/:fpIdParam/file" element={<FamilyPlacementFileList />} />
                  <Route path="/family-placement/:fpIdParam/beneficiary" element={<FamilyPlacementBeneficiaryList />} />
                </Route>                

                <Route element={<RequireAuth allowedPermissions={["PROFILE_LIST"]} />}>
                  <Route path="/profile" element={<ProfileList />} />
                  <Route path="/profile/:profileIdParam" element={<ProfileView />} />
                  <Route path="/profile/:profileIdParam/profile-extended" element={<ProfileExtendedView />} />
                  <Route path="/profile/:profileIdParam/file" element={<ProfileFileList />} />
                  <Route path="/profile/:profileIdParam/credential" element={<ProfileCredentialList />} />
                </Route>

                {/* Organization */}
                <Route element={<RequireAuth allowedPermissions={["ORGANIZATION_LIST"]} />}>
                  <Route path="/provider" element={<ProviderListPage />} />
                  <Route path="/provider/:providerIdParam" element={<ProviderView />} />
                  {/* <Route path="/provider/:providerIdParam/centers" element={<ProviderCentersView />} /> */}
                  <Route path="/provider/:providerIdParam/profiles" element={<ProviderProfilesView />} />
                  <Route path="/provider/:providerIdParam/services" element={<ProviderServicesView />} />

                  <Route path="/department" element={<DepartmentListPage />} />
                  <Route path="/department/:departmentIdParam" element={<DepartmentView />} />
                  <Route path="/department/:departmentIdParam/profiles" element={<DepartmentProfilesView />} />
                  <Route path="/department/:departmentIdParam/centers" element={<DepartmentCentersView />} />
                  <Route path="/department/:departmentIdParam/services" element={<DepartmentServicesView />} />

                  <Route path="/center" element={<CenterListPage />} />
                  <Route path="/center/:centerIdParam" element={<CenterView />} />
                  <Route path="/center/:centerIdParam/beneficiaries" element={<CenterBeneficiariesView />} />
                  <Route path="/center/:centerIdParam/services" element={<CenterServicesView />} />
                  <Route path="/center/:centerIdParam/profiles" element={<CenterProfilesView />} />
                  <Route path="/center/:centerIdParam/file" element={<CenterFileList />} />
                </Route>


                <Route element={<RequireAuth allowedPermissions={["ORGANIZATION_PROFILE_LIST"]} />}>
                  <Route path="/organizationProfile" element={<OrganizationProfileListPage />} />
                </Route>
                <Route element={<RequireAuth allowedPermissions={["CENTER_BENEFICIARY_LIST"]} />}>
                  <Route path="/centerBeneficiary" element={<CenterBeneficiaryListPage />} />
                </Route>

                {/* Service */}
                <Route element={<RequireAuth allowedPermissions={["SERVICE_LIST"]} />}>
                  <Route path="/service" element={<ServiceListPage />} />
                  <Route path="/service/:serviceIdParam" element={<ServiceView />} />
                  <Route path="/service/:serviceIdParam/beneficiaries" element={<ServiceBeneficiariesView />} />

                  <Route path="/serviceExpertBeneficiary" element={<ServiceExpertBeneficiaryListPage />} />
                </Route>

                <Route element={<RequireAuth allowedPermissions={["SERVICE_SUPPLY_LIST"]} />}>
                  <Route path="/serviceSupply" element={<ServiceSupplyListPage />} />
                </Route>

                {/* Competition menu - admin */}
                <Route
                  element={
                    <RequireAuth allowedPermissions={["COMPETITION_LIST"]} />
                  }
                ></Route>

                {/* Competition menu - evaluation */}
                <Route
                  element={
                    <RequireAuth allowedPermissions={["EVALUATION_LIST"]} />
                  }
                ></Route>

                {/* Tests menu - admin */}
                <Route
                  element={<RequireAuth allowedPermissions={["TEST_LIST"]} />}
                ></Route>

                {/* Form menu - candidates */}
                <Route
                  element={<RequireAuth allowedPermissions={["FORM_LIST"]} />}
                ></Route>

                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>

          <AppFooter colorMode={colorMode} />
        </div>

        <AppConfig
          inputStyle={inputStyle}
          onInputStyleChange={onInputStyleChange}
          rippleEffect={ripple}
          onRippleEffect={onRipple}
          menuMode={menuMode}
          onMenuModeChange={onMenuModeChange}
          inlineMenuPosition={inlineMenuPosition}
          onInlineMenuPositionChange={onInlineMenuPositionChange}
          colorMode={colorMode}
          onColorModeChange={onColorModeChange}
          menuTheme={menuTheme}
          onMenuThemeChange={onMenuThemeChange}
          topbarTheme={topbarTheme}
          onTopbarThemeChange={onTopbarThemeChange}
          theme={theme}
          onThemeChange={onThemeChange}
          isRTL={isRTL}
          onRTLChange={onRTLChange}
        />

        <AppRightMenu
          rightMenuActive={rightMenuActive}
          onRightMenuButtonClick={onRightMenuButtonClick}
        />

        {mobileMenuActive && <div className="layout-mask modal-in"></div>}
      </div>
    </RTLContext.Provider >
  );
};

export default App;
