import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';


import { translatedMessage } from '../../service/LanguageService';
import ApiService from '../../service/ApiService';
import GeneralUtils from '../../utilities/GeneralUtils';
import EnumService from '../../service/EnumService';

const maxFileSize = 5242880
const acceptFile = ".pdf,image/*"
const emptyFileRecord = { name: '', issuer: '', registrationNumber: '', registrationDate: '', usage: null }

const StorageFileUpload = (props) => {
    const [totalSize, setTotalSize] = useState(0);
    const [accept, setAccept] = useState(acceptFile);
    const [acceptDescription, setAcceptDescription] = useState("");
    const [file, setFile] = useState(emptyFileRecord);
    const [usageLookup, setUsageLookup] = useState([]);
    const [filteredentityLookups, setFilteredentityLookups] = useState([]);
    const [issuers, setIssuers] = useState([]);

    const toast = useRef(null);

    const fileUploadRef = useRef(null);

    useEffect(() => {
        let _acceptedTypes = acceptFile
        let _acceptedTypesDescription = translatedMessage("file.upload.accept.pdf") + ", " + translatedMessage("file.upload.accept.image")

        const getIssuers = async () => {
            const _issuers = await EnumService.getEntityLookupByField("NOM:DOC_ISSUER")
            setIssuers(_issuers)
        }

        if (props?.accept) {
            _acceptedTypes = ""
            _acceptedTypesDescription = ""
            props?.accept.forEach((item, index) => {
                if (index > 0) {
                    _acceptedTypes += ","
                    _acceptedTypesDescription += ", "
                }
                _acceptedTypes += GeneralUtils.getFileTypeByCode(item)
                _acceptedTypesDescription += translatedMessage("file.upload.accept." + item)
            })
        }

        setAccept(_acceptedTypes)
        setAcceptDescription(_acceptedTypesDescription)
        setUsageLookup(props?.usageLookup)
        getIssuers()
    }, [props]);

    const hideFileUploadDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setFile(emptyFileRecord)
    };

    const roundedSize = (size) => {
        return (size / (1024 * 1024)).toFixed(2)
    }

    const _onBeforeUpload = (event) => {
        event.formData.append('folderId', props.folderId?.toString());
        event.formData.append('deletePrevious', props.deletePrevious);

        if (props.usageId) {
            event.formData.append('usageId', props.usageId.toString());
        } else if (file.usage && file.usage.value) {
            event.formData.append('usageId', file.usage.value.toString());
        }

        if (props.hasFileProperties) {
            if (file.issuer) { 
                let _issuer = file.issuer.label ? file.issuer.label : file.issuer
                event.formData.append('issuer', _issuer) 
            }
            if (file.registrationNumber) { event.formData.append('registrationNumber', file.registrationNumber) }
            if (file.registrationDate) { event.formData.append('registrationDate', file.registrationDate) }
        }
    };

    const _onBeforeSend = (event) => {
        event.xhr.open('POST', ApiService.getBaseUrl() + '/storage/file', true);
        event.xhr.setRequestHeader('Authorization', 'Bearer ' + ApiService.getToken());
    };

    const _onUpload = (event) => {
        if (typeof props.onUploadSuccess === 'function') {
            props.onUploadSuccess(props.entityId, JSON.parse(event.xhr.response));
        }
        hideFileUploadDialog();
    };

    const _onError = (event) => {
        setTotalSize(0);
        fileUploadRef.current.clear()

        if (typeof props.onUploadError === 'function') {
            let errorMessage = "generic.save.error";
            if (event.xhr?.response?.trim()?.length > 0) {
                let response = JSON.parse(event.xhr?.response);

                if (response && response.message) {
                    errorMessage = response && response.message;
                }
            }

            props.onUploadError(translatedMessage(errorMessage));
        }

        hideFileUploadDialog();
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onErrorRemove = (file, callback) => {
        callback();
    }

    const _onClear = () => {
        setTotalSize(0);
    }

    const checkFileTypeError = (file) => {
        if (props?.accept) {
            return GeneralUtils.checkFileMimeByCode(props?.accept, file.type)
        } else {
            if (file.type !== "application/pdf" && file.type.substring(0, 6) !== "image/") {
                return true
            }
            return false
        }
    }

    const _onSelect = (event) => {
        let _totalSize = totalSize;
        let files = event.files;
        let _maxFileSize = props.maxFileSize ? props.maxFileSize : maxFileSize
        let isError = false

        Object.keys(files).forEach((key) => {
            if (files[key].size && files[key].size <= _maxFileSize) {
                _totalSize += files[key].size || 0;
            }
            if (files[key]) {
                isError = checkFileTypeError(files[key])
                if (isError) {
                    _totalSize -= files[key].size || 0;
                }
            }
        });

        setTotalSize(_totalSize)
        if (isError) {
            toast?.current?.show({ severity: 'error', summary: translatedMessage("file.type.error"), life: 5000 });
        }
    }

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }

    const itemTemplate = (_file, _props) => {
        if (checkFileTypeError(_file)) {
            onErrorRemove(_file, _props.onRemove)
        }
        return (
            <div>
                <div className="flex flex-row align-items-center justify-content-between pl-1 pr-1" style={{ minHeight: '60px' }}>
                    <div className='text-align-left'>{_file.name}</div>
                    <div className="flex flex-row align-items-center justify-content-between pl-3">

                        <div className='flex align-items-center text-align-right'>
                            <span className='mr-4'>{roundedSize(_file.size)} MB</span>
                            <Button type="button" icon="pi pi-times" onClick={() => onTemplateRemove(_file, _props.onRemove)} />
                        </div>
                    </div>
                </div>
                <div className='grid'>
                    {props.hasFileProperties &&
                        <>
                            <div className='col-12 md:col-3'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <AutoComplete
                                            dropdown
                                            id="issuer"
                                            name="issuer"
                                            value={file.issuer}
                                            suggestions={filteredentityLookups}
                                            completeMethod={(e) => searchListItems(e, issuers)}
                                            virtualScrollerOptions={{ itemSize: 38 }}
                                            field="label"
                                            onChange={(e) => setFile({ ...file, issuer: e.value })}
                                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        />
                                        <label htmlFor="issuer">{translatedMessage("file.issuer")}</label>
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 md:col-3'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="registrationNumber" id="registrationNumber" value={file.registrationNumber}
                                            onChange={(e) => setFile({ ...file, registrationNumber: e.target.value })} />
                                        <label htmlFor="registrationNumber">{translatedMessage("file.registrationNumber")}</label>
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 md:col-3'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="registrationDate" name="registrationDate" value={file.registrationDate} dateFormat="dd-mm-yy"
                                            onChange={(e) => setFile({ ...file, registrationDate: e.value })} />
                                        <label htmlFor="registrationDate">{translatedMessage("file.registrationDate")}</label>
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                    {!props.usageId &&
                        <div className='col-12 md:col-3'>
                            <Dropdown value={file.usage?.value} options={usageLookup} optionLabel="label" optionValue="value"
                                onChange={(e) => onUsageChange(e.value)} placeholder={translatedMessage('file.usage.select')} />
                        </div>
                    }
                </div>
            </div>
        );
    };

    const onUsageChange = (usageId) => {
        let _usage = props?.usageLookup.filter(item => item.value === usageId)
        setFile({ ...file, usage: _usage[0] ? _usage[0] : [] })
    }

    const headerTemplate = (options) => {
        let { className, chooseButton, uploadButton, cancelButton } = options;

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                <div className='mb-1'>{chooseButton}</div>
                <div className='mb-1'>{uploadButton}</div>
                <div className='mb-1'>{cancelButton}</div>
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{roundedSize(totalSize)} MB</span>
                </div>
            </div>
        );
    };

    const dialogHeader = () => {
        return (
            <>
                <div>{translatedMessage('generic.file.uploadFile')}</div>
                {props.multiple &&
                    <div className='mt-1 pcn-dialog-subtitle'>{translatedMessage('file.upload.multipleFIles')}</div>
                }
                <div className='mt-1 pcn-dialog-subtitle'>{translatedMessage('file.upload.fileTypes')} {acceptDescription}</div>
                <div className='mt-1 pcn-dialog-subtitle'>
                    {translatedMessage('file.upload.maxFileSize')} {roundedSize(props.maxFileSize ? props.maxFileSize : maxFileSize)} MB
                </div>
                {props.deletePrevious &&
                    <div className='mt-1 pcn-dialog-subtitle text-orange-500'>
                        <i className="pi pi-exclamation-triangle mr-1"></i>
                        {translatedMessage('file.upload.deletePrevious.message')}
                    </div>
                }
            </>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props.visible}
                header={dialogHeader}
                modal
                onHide={hideFileUploadDialog}
                className="pcn-dialog p-fluid"
            >
                <FileUpload
                    mode="advanced"
                    name="file"
                    ref={fileUploadRef}
                    url={ApiService.getBaseUrl() + '/storage/file'}
                    accept={accept}
                    maxFileSize={props.maxFileSize ? props.maxFileSize : maxFileSize}
                    invalidFileSizeMessageDetail={""}
                    invalidFileSizeMessageSummary={translatedMessage("file.upload.maxFileSize.error")}
                    multiple={props.multiple && !props.hasFileProperties}
                    itemTemplate={itemTemplate}
                    onSelect={_onSelect}
                    onBeforeUpload={_onBeforeUpload}
                    onBeforeSend={_onBeforeSend}
                    onUpload={_onUpload}
                    onError={_onError}
                    onClear={_onClear}
                    chooseLabel={translatedMessage('generic.file.choose')}
                    uploadLabel={translatedMessage('generic.file.uploadFile')}
                    cancelLabel={translatedMessage('generic.cancel')}
                    headerTemplate={headerTemplate}
                />
            </Dialog>
        </>
    );
};

export default StorageFileUpload;