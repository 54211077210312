import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';

import { Toast } from 'primereact/toast';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { CenterService } from '../../service/CenterService';
import useNavProps from '../../hooks/useNavProps';
import CenterToolbar from './CenterToolbar';

const CenterFileList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [center, setCenter] = useState(null);
    const [error] = useState({ isError: false, message: '' });
    
    const toast = useRef(null);
    const centerService = useMemo(() => new CenterService(), []);

    const { centerIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getCenter = async () => {
            await centerService.getCenter(centerIdParam)
                .then(async (_data) => {
                    setCenter(_data);
                    setNavPropsValue('center', _data.id, _data.name);
                    setIsLoading(false);

                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCenter();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [centerService, centerIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <div className="grid h-full">
                <Toast ref={toast}></Toast>                            
                <div className="col-12">
                    <div className="card h-full">
                        <CenterToolbar center={center} code="FILES" />

                        <StorageFolderFileList
                            folderId={center?.folderId}
                            folderName={center?.name}
                            showUploadButton={true}
                            pageTitle={translatedMessage("generic.files")}
                            multiple={false}
                            entityName={"CENTER"}
                            hasFileProperties={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default CenterFileList;
