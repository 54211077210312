import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../ExportButtonComponent';
import EnumService from '../../service/EnumService';
import { ProfileService } from '../../service/ProfileService';
import ProfileEditDialogComponent from './ProfileEditDialogComponent';
import UserCreateForProfileDialogComponent from '../user/UserCreateForProfileDialogComponent';
import ProfileExportDataUtils from '../../utilities/ProfileExportDataUtils';
import GeneralUtils from '../../utilities/GeneralUtils';

const ProfileListComponent = () => {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(false);
    const [profiles, setProfiles] = useState(null);
    const [profile, setProfile] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
    const [genders, setGenders] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            pin: { value: null, matchMode: FilterMatchMode.CONTAINS },
            gender: { value: null, matchMode: FilterMatchMode.EQUALS },
        },
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const propToColumnMap = {
        fullName: 'first_name',
        pin: 'pin',
        gender: 'gender'
    };

    const profileService = useMemo(() => new ProfileService(), []);

    useEffect(() => {
        setFirstLoading(true);

        setFirstLoading(false)
    }, []);

    useEffect(() => {
        lazyLoadData();

        const getGender = async () => {
            setGenders(await EnumService.getEnumByName('Gender'));
        };

        getGender()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {}
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC"
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id'
        criteria.fullName = lazyParams.filters.fullName.value
        criteria.pin = lazyParams.filters.pin.value
        criteria.gender = lazyParams.filters.gender.value

        return criteria
    }

    const loadData = async () => {
        let criteria = setCriteria()

        criteria.startRow = lazyParams.first
        criteria.pageSize = lazyParams.rows

        await profileService.getProfilesByCriteria(criteria)
            .then((data) => {
                setProfiles(data.items)
                setTotalRecords(data.totalCount)
                setLoading(false)
            })
            .catch((error) => {
                setError(true)
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            })
    }

    const lazyLoadData = () => {
        setLoading(true);
        loadData();
    }

    const addNew = () => {
        setShowEditDialog(true);
    };

    const editData = (_profile) => {
        setProfile({ ..._profile });
        setShowEditDialog(true);
    };

    const createUser = (_profile) => {
        setProfile({ ..._profile });
        setShowCreateUserDialog(true);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('LEGAL_REPRESENTATIVE_CREATE') &&
                        <Button
                            label={translatedMessage("person.add")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ProfileExportDataUtils.profileExtendedTableHeader(true)}
                sortOrderHeader={ProfileExportDataUtils.profileExtendedSortOrderHeader(true)}
                fileName={GeneralUtils.computeExportFileName("person.persons")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            profileService.getProfilesByCriteria(criteria)
                .then((data) => {
                    let exportData = []
                    data.items.forEach(item => {
                        let exportItem = ProfileExportDataUtils.computeExtendedExportFields(item, true)

                        exportData.push(exportItem)
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const displayEditDialog = (_display) => {
        setShowEditDialog(_display)
        setProfile(null)
    }

    const genderRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={genders}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={options.label}
        />
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('PROFILE_VIEW') &&
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => navigate(`/profile/${rowData.id}`)}
                            title={translatedMessage("generic.view")} />
                    }
                    {hasPermission('PROFILE_EDIT') &&
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")} />
                    }
                    {hasPermission('PROFILE_VIEW') &&
                        <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => navigate(`/profile/${rowData.id}/file`)}
                            title={translatedMessage("legalRep.files")} />
                    }
                    {hasPermission("PROFILE_EDIT") &&
                        (
                            <Button
                                icon="pi pi-user-plus"
                                className="p-button-rounded p-button-info m-1"
                                onClick={() => createUser(rowData)}
                                tooltip={translatedMessage("user.create")}
                                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                                disabled={rowData.user}
                            />
                        )}
                </div>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{translatedMessage("person.persons")}</h5>
                    </div>
                    <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={profiles}
                        lazy
                        dataKey="id"
                        paginator
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onPage={onPage} onSort={onSort} onFilter={onFilter}
                        loading={loading}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                    >
                        <Column
                            field="fullName"
                            header={translatedMessage("generic.name")}
                            sortable
                            headerStyle={{ width: '20%', minWidth: '8rem' }}
                            filter
                            filterField="fullName"
                            showFilterMenu={false}
                        />
                        <Column
                            field="pin"
                            header={translatedMessage("profile.pin")}
                            body={(e) => e.pin ? e.pin : "-"}
                            sortable
                            headerStyle={{ width: '12%', minWidth: '8rem' }}
                            filter
                            filterField='pin'
                            showFilterMenu={false}
                        />
                        <Column
                            field="gender"
                            header={translatedMessage("profile.gender")}
                            body={(e) => e.gender ? translatedMessage("Gender." + e.gender) : "-"}
                            sortable
                            headerStyle={{ width: '12%', minWidth: '8rem' }}
                            filter
                            filterField='gender'
                            showFilterMenu={false}
                            filterElement={genderRowFilterTemplate}
                        />
                        <Column body={actionBodyTemplate} />
                    </DataTable>

                    <ProfileEditDialogComponent
                        value={profile}
                        visible={showEditDialog}
                        afterSave={lazyLoadData}
                        visibleSetter={(value) => displayEditDialog(value)}
                    />

                    <UserCreateForProfileDialogComponent
                        profile={profile}
                        visible={showCreateUserDialog}
                        afterSave={lazyLoadData}
                        visibleSetter={(value) => setShowCreateUserDialog(value)}
                    />
                </>
            )}
        </>
    );
};

export default ProfileListComponent;
