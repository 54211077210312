import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { getIn, useFormik } from "formik";
import { translatedMessage } from '../../service/LanguageService';
import OrganizationProfileAutocompleteFieldComponent from '../../components/organizationProfile/OrganizationProfileAutocompleteFieldComponent';

const OrganizationProfileChooserDialog = (props) => {
    const [selectedOrganizationProfile, setSelectedOrganizationProfile] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        setSelectedOrganizationProfile(props?.selectedOrganizationProfile);
    }, [props]);

    const formik = useFormik({
        initialValues: { organizationProfile: selectedOrganizationProfile },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.organizationProfile) {
                errors.organizationProfile = translatedMessage("form.error.profile.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            if (typeof props.saveFunction === 'function') {
                props.saveFunction(data.organizationProfile);
                hideDialog();
            }
            else {
                toast?.current?.show({ severity: 'error', summary: 'No save function provided !!!', life: 5000 });
            }            
        }
    });

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false)
            formik.resetForm()
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="responsible-form" type="submit" />
        </>
    );

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    // const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={props?.title ? props.title : translatedMessage('beneficiary.responsible')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >

                <form id="responsible-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <OrganizationProfileAutocompleteFieldComponent
                            setOrganizationProfile={(e) => setSelectedOrganizationProfile(e)}
                            organizationProfile={selectedOrganizationProfile}
                            organization={props?.organization}
                            validationError={isFormFieldValid('organizationProfile')}
                            title={props?.fieldLabel}
                        />
                    </div>
                </form>
            </Dialog>
        </>);
};

export default OrganizationProfileChooserDialog;
