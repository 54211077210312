import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';

import { Toast } from 'primereact/toast';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';
import FamilyPlacementToolbar from './FamilyPlacementToolbar';
import useNavProps from '../../hooks/useNavProps';

const FamilyPlacementFileList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [familyPlacement, setFamilyPlacement] = useState(null);
    const [error] = useState({ isError: false, message: '' });
    
    const toast = useRef(null);
    const { setNavPropsValue } = useNavProps();

    const familyPlacementService = useMemo(() => new FamilyPlacementService(), []);

    const { fpIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getFP = async () => {
            await familyPlacementService.getFamilyPlacement(fpIdParam)
                .then(async (_data) => {
                    setFamilyPlacement(_data)

                    // await checkEditPermission()
                    setNavPropsValue('family-placement', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getFP();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyPlacementService, fpIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <div className="grid h-full">
                <Toast ref={toast}></Toast>                            
                <div className="col-12">
                    <div className="card h-full">
                        <FamilyPlacementToolbar familyPlacement={familyPlacement} code="FILES" />

                        <StorageFolderFileList
                            folderId={familyPlacement?.folderId}
                            folderName={familyPlacement?.profile?.fullName}
                            showUploadButton={true}
                            pageTitle={translatedMessage("generic.files")}
                            multiple={false}
                            entityName={"FAMILY_PLACEMENT"}
                            hasFileProperties={true}
                            readOnly={!familyPlacement?.actions?.ADD_FAMILY_PLACEMENT}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default FamilyPlacementFileList;
