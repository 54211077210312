import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import DataTableUtils from '../../utilities/DataTableUtils';
import StorageFileUpload from '../file/StorageFileUpload';
import EnumService from '../../service/EnumService';
import ProfileExtendedEditDialog from '../../pages/profile/ProfileExtendedEditDialog';
import BeneficiaryEditProfileExtendedDialog from '../../pages/beneficiary/BeneficiaryEditProfileExtendedDialog';
import ProfilelUtils from '../../utilities/ProfilelUtils';

const ProfileExtendedViewComponent = (props) => {
    const [profile, setProfile] = useState(null);
    const [beneficiary, setBeneficiary] = useState(null);

    const [showProfileEditDialog, setShowProfileEditDialog] = useState(false);
    const [showBeneficiaryEditDialog, setShowBeneficiaryEditDialog] = useState(false);

    const [editSection, setEditSection] = useState(null);
    const [dialogTitle, setDialogTitle] = useState("profile.details");
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [usageLookup, setUsageLookup] = useState([]);
    const [editPermission, setEditPermission] = useState(null);

    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getUsages = async () => {
            const usageLookupResponse = await EnumService.getFileUsageByEntity("PROFILE");
            setUsageLookup(usageLookupResponse)
        }

        if (props.entity) {
            if (props.entityName === 'BENEFICIARY') {
                setBeneficiary(props.entity)
                setProfile(props.entity.profile)
            } else if (props.entityName === 'AMP') {
                setProfile(props.entity)
            } else if (props.entityName === 'FAMILY_PLACEMENT') {
                setProfile(props.entity)
            } else if (props.entityName === 'PROFILE') {
                setProfile(props.entity)
            }
        }

        setEditPermission(props.entityName + '_EDIT')

        getUsages()

    }, [props]);


    const updateData = (_profileExtended) => {
        setProfile({ ...profile, profileExtended: _profileExtended })
    }

    const getEditButton = (_section, _title) => {
        return <Button
            text
            className="aspc-button-icon-slim p-button p-button-icon-only"
            onClick={() => editDialog(_section, _title)}
            disabled={!hasPermission(editPermission)}
            tooltip={translatedMessage('generic.edit')}
            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
        >
            <FontAwesomeIcon icon='fa-solid fa-pencil' />
        </Button>
    }

    const navigateToFiles = () => {
        let entityNav = 'profile'
        let entityId = profile.id
        if (props.entityName === 'BENEFICIARY') {
            entityNav = 'beneficiary'
            entityId = beneficiary.id
        }

        navigate(`/${entityNav}/${entityId}/file`)
    }

    const getUploadButton = (_isDisabled = false) => {
        return <>
            <Button
                text
                className="aspc-button-icon-slim p-button p-button-icon-only ml-1"
                onClick={() => uploadFile()}
                tooltip={translatedMessage("generic.file.upload")}
                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                disabled={_isDisabled || !hasPermission(editPermission)}
            >
                <FontAwesomeIcon icon='fa-solid fa-upload' />
            </Button>
            <Button
                text
                className="aspc-button-icon-slim p-button p-button-icon-only ml-1"
                onClick={() => navigateToFiles()}
                tooltip={translatedMessage("generic.files")}
                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                disabled={_isDisabled}
            >
                <FontAwesomeIcon icon='fa-regular fa-file' />
            </Button>
        </>
    }

    const editDialog = (_section, _title) => {
        if (_section) {
            setEditSection(_section)
            setDialogTitle(_title)
            setShowProfileEditDialog(props?.entityName === 'PROFILE' || props?.entityName === 'AMP' || props?.entityName === 'FAMILY_PLACEMENT')
            setShowBeneficiaryEditDialog(props?.entityName === 'BENEFICIARY')
        }
    }

    const uploadFile = () => {
        setShowUploadDialog(true);
    };

    const onFileUploadSuccess = (id, response) => {
        toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast?.current?.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    return (
        <>
            <Toast ref={toast} />

            <div className='w-full grid pcn-data-view-panel mb-5'>
                <div className='col-12 flex align-items-center'>
                    <span className='entity-view-subtitle mr-2'>{translatedMessage("profile.extended.identity.title")}</span>
                    {getEditButton("IDENTITY", "profile.extended.identity.title")}
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.idStatus')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.idStatusLabel)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.idCardSeries')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.idCardSeries)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.idCardNumber')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.idCardNumber)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.idCardExpiryDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(profile?.profileExtended?.idCardExpiryDate)}</div>
                </div>

                {props?.entityName === 'BENEFICIARY' &&
                    <>
                        <div className='col-12 flex align-items-center mt-3'>
                            <span className='entity-view-subtitle mr-2'>{translatedMessage("profile.extended.adoption.guardianship.title")}</span>
                            {getEditButton("ADOPTION", "profile.extended.adoption.guardianship.title")}
                            {getUploadButton(!profile?.profileExtended?.canBeAdopted)}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('profile.extended.canBeAdopted')}</div>
                            <div className='filed-value'>{DataTableUtils.yesNoTemplate(profile?.profileExtended?.canBeAdopted)}</div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('profile.extended.hasGuardianship')}</div>
                            <div className='filed-value'>{DataTableUtils.yesNoTemplate(profile?.profileExtended?.hasGuardianship)}</div>
                        </div>
                    </>
                }

                <div className='col-12 flex align-items-center mt-3'>
                    <span className='entity-view-subtitle mr-2'>{translatedMessage("profile.extended.health.title")}</span>
                    {getEditButton("HEALTH", "profile.extended.health.title")}
                    {getUploadButton(!profile?.profileExtended?.disabilityDegreeId)}
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.healthStatus')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.healthStatusLabel)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.disabilityDegree')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.disabilityDegreeLabel)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.disabilityType')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.disabilityTypeLabel)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.diseaseType')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.diseaseTypeLabel)}</div>
                </div>

                {props?.entityName === 'BENEFICIARY' &&
                    <>
                        <div className='col-12 flex align-items-center mt-3'>
                            <span className='entity-view-subtitle mr-2'>{translatedMessage("profile.extended.schoolGuidance.title")}</span>
                            {getEditButton("SCHOOL_GUIDANCE", "profile.extended.schoolGuidance.title")}
                            {getUploadButton(!profile?.profileExtended?.needsSchoolGuidance)}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('profile.extended.needsSchoolGuidance')}</div>
                            <div className='filed-value'>{DataTableUtils.yesNoTemplate(profile?.profileExtended?.needsSchoolGuidance)}</div>
                        </div>
                    </>
                }
                <div className='col-12 flex align-items-center mt-3'>
                    <span className='entity-view-subtitle mr-2'>{translatedMessage("profile.extended.education.title")}</span>
                    {getEditButton("EDUCATION", "profile.extended.education.title")}
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.educationLevel')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.educationLevelLabel)}</div>
                </div>
                {props?.entityName === 'BENEFICIARY' &&
                    <>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('profile.extended.educationStatus')}</div>
                            <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.educationStatusLabel)}</div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('profile.extended.schoolName')}</div>
                            <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.schoolName)}</div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('profile.extended.schoolGrade')}</div>
                            <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.schoolGrade)}</div>
                        </div>
                    </>
                }

                <div className='col-12 flex align-items-center mt-3'>
                    <span className='entity-view-subtitle mr-2'>{translatedMessage("profile.extended.employment.title")}</span>
                    {getEditButton("EMPLOYMENT", "profile.extended.employment.title")}
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.employmentStatus')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.employmentStatusLabel)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.employmentWorkCapacity')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.employmentWorkCapacityLabel)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.extended.employerName')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.profileExtended?.employerName)}</div>
                </div>

                {ProfilelUtils.showMaritalStatus(props?.entityName) &&
                    <>
                        <div className='col-12 flex align-items-center mt-3'>
                            <span className='entity-view-subtitle mr-2'>{translatedMessage("profile.extended.maritalStatus.title")}</span>
                            {getEditButton("MARITAL", "profile.extended.maritalStatus.title")}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('profile.extended.maritalStatus')}</div>
                            <div className='filed-value'>{profile?.profileExtended?.maritalStatus ? translatedMessage("MaritalStatus." + profile?.profileExtended?.maritalStatus) : "-"}</div>
                        </div>
                    </>
                }
            </div>


            <ProfileExtendedEditDialog
                value={profile}
                editSection={editSection}
                dialogTitle={dialogTitle}
                visible={showProfileEditDialog}
                afterSave={(savedData) => updateData(savedData)}
                visibleSetter={() => setShowProfileEditDialog(false)}
            />

            <BeneficiaryEditProfileExtendedDialog
                value={profile}
                editSection={editSection}
                dialogTitle={dialogTitle}
                visible={showBeneficiaryEditDialog}
                afterSave={(savedData) => updateData(savedData)}
                visibleSetter={() => setShowBeneficiaryEditDialog(false)}
            />

            <StorageFileUpload
                entityId={null}
                folderId={profile?.folderId}
                usageId={null}
                deletePrevious={false}
                multiple={false}
                accept={["pdf", "image"]}
                visible={showUploadDialog}
                visibleSetter={setShowUploadDialog}
                onUploadSuccess={onFileUploadSuccess}
                onUploadError={onFileUploadError}
                usageLookup={usageLookup}
                hasFileProperties={true}
            />
        </>
    );
};

export default ProfileExtendedViewComponent;
