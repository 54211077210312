import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import CenterListComponent from "../../components/center/CenterListComponent";
import { DepartmentService } from '../../service/DepartmentService';
import DepartmentToolbar from './DepartmentToolbar';

const DepartmentCentersView = () => {
    const [department, setDepartment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const departmentService = useMemo(() => new DepartmentService(), []);

    const { departmentIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getOrganization = async () => {
            await departmentService.getDepartment(departmentIdParam)
                .then(async (_data) => {
                    setDepartment(_data)
                    
                    setNavPropsValue('department', _data.id, _data.name)
                    setIsLoading(false)
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        };

        getOrganization()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentService, departmentIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <DepartmentToolbar department={department} code="CENTERS" />

                                <CenterListComponent parent={department} pageTitle={translatedMessage("provider.centers")} useDialog={true}/>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default DepartmentCentersView;
