import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { HearingService } from "../../service/HearingService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import { getCurrentUser } from '../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from "../../service/EnumService";
import DeleteModalComponent from "../DeleteModalComponent";
import HearingEditDialogComponent from "./HearingEditDialogComponent";
import DataTableUtils from "../../utilities/DataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import OrganizationProfileChooserDialog from "../organizationProfile/OrganizationProfileChooserDialog";
import OrganizationDataTableUtils from "../../utilities/OrganizationDataTableUtils";
import { Dropdown } from "primereact/dropdown";
import SecurityService from '../../service/SecurityService';

const sortOrderHeader = ["firstName", "lastName", "pin", "birthday", "homeAddress", "motherName", "fatherName", "type", "offenseName", "hearingLocation", "hearingSpace", "conclusion", "date", "notice", "organizationProfile", "comment", "createdOn"]

const tableHeader = [
    translatedMessage("generic.firstName"),
    translatedMessage("generic.lastName"),
    translatedMessage("profile.pin"),
    translatedMessage("profile.birthday"),
    translatedMessage("hearing.homeAddress"),
    translatedMessage("hearing.motherName"),
    translatedMessage("hearing.fatherName"),
    translatedMessage("hearing.type"),
    translatedMessage("hearing.offenseName"),
    translatedMessage("hearing.hearingLocation"),
    translatedMessage("hearing.hearingSpace"),
    translatedMessage("hearing.conclusion"),
    translatedMessage("hearing.date"),
    translatedMessage("hearing.notice"),
    translatedMessage("hearing.responsible"),
    translatedMessage("hearing.comment"),
    translatedMessage("generic.created.on")
]

const HearingListComponent = (props) => {
    const [hearings, setHearings] = useState([]);
    const [selectedHearing, setSelectedHearing] = useState(null);

    const [conclusionList, setConclusionList] = useState([])
    const [hearingTypeList, setHearingTypeList] = useState([])
    const [hearingLocationList, setHearingLocationList] = useState([])

    const [canCreate, setCanCreate] = useState(true);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            responsibleName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.EQUALS },
            conclusion: { value: null, matchMode: FilterMatchMode.IN },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);

    const propToColumnMap = {
        responsibleName: "reporter_name",
        name: "name",
        tyoe: "type",
        conclusion: "conclusion_id",
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [editResponsibleDialogVisible, setEditResponsibleDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();

    const hearingService = useMemo(() => new HearingService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getConclusions = async () => {
            const _conclusionList = await EnumService.getEntityLookupByField("NOM:HEARING_CONCLUSION")
            setConclusionList(_conclusionList)
        }

        const getHearingTypeList = async () => {
            const _hearingTypeList = await EnumService.getEnumByName("HearingType")
            setHearingTypeList(_hearingTypeList)
        }

        const getHearingLocationList = async () => {
            const _hearingLocationList = await EnumService.getEnumByName("HearingLocation")
            setHearingLocationList(_hearingLocationList)
        }

        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_HEARING'));
        };

        getConclusions();
        getHearingTypeList();
        getHearingLocationList();
        checkCanCreate()
        setFirstLoading(false);
    }, [hearingService, props]);

    useEffect(() => {
        loadHearings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("HearingList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";

        criteria.name = lazyParams.filters.name.value;
        criteria.responsibleProfileName = lazyParams.filters.responsibleName.value;
        criteria.type = lazyParams.filters.type.value;
        criteria.conclusionIds = lazyParams.filters.conclusion.value;
        criteria.noticeId = props?.notice?.id;
        return criteria;
    };

    const loadHearings = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await hearingService
            .getHearingsByCriteria(criteria)
            .then((data) => {
                setHearings(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    }

    const saveHearingOrganizationProfile = async (_organizationProfile) => {
        let _hearing = { ...selectedHearing }
        _hearing.organizationProfile = _organizationProfile;

        await hearingService.saveHearing(_hearing)
            .then(savedData => {
                loadHearings();
            })
            .catch(error => {
                toast?.current?.show({ severity: "error", summary: translatedMessage(error), life: 5000 });
            });
    }

    const updateList = async (savedHearing, isNew) => {
        loadHearings();
    };

    const showHearingViewPage = (_hearing) => {
        navigate(`/hearing/${_hearing.id}`);
    };

    const showNewDialog = () => {
        setSelectedHearing(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (_hearing) => {
        setSelectedHearing(_hearing);
        setEditDialogVisible(true);
    };

    const showEditResponsible = (_hearing) => {
        setSelectedHearing(_hearing);
        setEditResponsibleDialogVisible(true);
    };

    const showDeleteDialog = (_hearing) => {
        setSelectedHearing(_hearing);
        setDeleteDialogVisible(true);
    };

    const deleteHearing = () => {
        hearingService
            .deleteHearing(selectedHearing.id)
            .then(() => {
                let _hearings = hearings.filter(
                    (item) => item.id !== selectedHearing.id
                );
                setHearings(_hearings);
                toast?.current?.show({
                    severity: "success",
                    summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedHearing(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("HEARING_CREATE") &&
                        (
                            <Button
                                label={translatedMessage("hearing.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            hearingService
                .getHearingsByCriteria(criteria)
                .then((data) => {
                    let exportData = data.items.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item;
                        exportItem.type = item.type ? translatedMessage('HearingType.' + item.type) : "-";
                        exportItem.hearingLocation = item.hearingLocation ? translatedMessage('HearingLocation.' + item.hearingLocation) : "-";
                        exportItem.conclusion = item.conclusion?.label;
                        exportItem.notice = exportItem.notice ? item.notice?.noticeType?.label + " / " + GeneralUtils.formatDate(item.notice?.noticeDate) + (item.notice?.beneficiary ? (" - " + item.notice?.beneficiary?.profile?.fullName) : "") : '';
                        exportItem.organizationProfile = item.organizationProfile?.profile?.fullName;
                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn)
                        return exportItem;
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.computeExportFileName("hearing.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-hearing justify-content-end">
                {hasPermission("HEARING_VIEW") &&
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => showHearingViewPage(rowData)}
                        tooltip={translatedMessage("generic.view")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                        disabled={!rowData.actions?.VIEW_HEARING}
                    />
                }
                {hasPermission("HEARING_EDIT") &&
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info m-1"
                        onClick={() => showEditDialog(rowData)}
                        tooltip={translatedMessage("generic.edit")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                        disabled={!rowData.actions?.ADD_HEARING}
                    />
                }
                {hasPermission('HEARING_EDIT') &&
                    <Button
                        className="p-button-rounded p-button-info p-button-icon-only m-1"
                        onClick={() => showEditResponsible(rowData)}
                        title={translatedMessage("notice.responsible")} 
                        disabled={!rowData.actions?.ADD_HEARING}>
                        <FontAwesomeIcon icon='fa-solid fa-user-tie' />
                    </Button>
                }
                {hasPermission("HEARING_CREATE") &&
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning m-1"
                        onClick={() => showDeleteDialog(rowData)}
                        tooltip={translatedMessage("generic.delete")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                        disabled={!rowData.actions?.ADD_HEARING}
                    />
                }
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateTemplate(rowData.date)
    }

    const typeRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={hearingTypeList}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={options.label}
        />
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-hearing">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    {props.pageTitle &&
                        <div className='w-full text-align-left'>
                            <h5 className='mb-2'>{props.pageTitle}</h5>
                        </div>
                    }
                    {!props.pageTitle &&
                        <h3 className="mb-0">
                            {translatedMessage("hearing.list")}
                        </h3>
                    }

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0 pt-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={hearings}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        <Column
                            field="name"
                            header={translatedMessage("hearing.name")}
                            headerStyle={{ minWidth: "8rem" }}
                            body={(e) => e.firstName + ' ' + e.lastName}
                            sortable
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="type"
                            header={translatedMessage("hearing.type")}
                            headerStyle={{ minWidth: "8rem" }}
                            body={(e) => translatedMessage("HearingType." + e.type)}
                            sortable
                            filter
                            showFilterMenu={false}
                            filterElement={typeRowFilterTemplate}
                        />
                        <Column
                            field="responsibleName"
                            header={translatedMessage("hearing.responsible")}
                            headerStyle={{ minWidth: "8rem" }}
                            body={(e) => OrganizationDataTableUtils.getOrganizationProfileTemplate(e.organizationProfile)}
                            sortable
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="conclusion"
                            header={translatedMessage("hearing.conclusion")}
                            body={(e) => e.conclusion?.label}
                            headerStyle={{ minWidth: "8rem" }}
                            filter
                            filterElement={(e) => DataTableUtils.entityLookupFilterTemplate(e, conclusionList, translatedMessage('hearing.conclusion'))}
                            filterHeaderClassName="pcn-filter-column-250"
                            showFilterMenu={false}
                        />
                        <Column
                            field="date"
                            header={translatedMessage("hearing.date")}
                            headerStyle={{ minWidth: "8rem" }}
                            body={dateBodyTemplate}
                            sortable
                        />
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <HearingEditDialogComponent
                        value={selectedHearing}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        conclusionList={conclusionList}
                        hearingTypeList={hearingTypeList}
                        hearingLocationList={hearingLocationList}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedHearing ? selectedHearing.firstName + ' ' + selectedHearing.lastName + ' / ' + selectedHearing.conclusion.label + ' / ' + GeneralUtils.formatDate(selectedHearing.date) : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteHearing()}
                    />

                    <OrganizationProfileChooserDialog
                        selectedOrganizationProfile={selectedHearing?.organizationProfile}
                        organization={getCurrentUser().organizationProfile?.organization}
                        visible={editResponsibleDialogVisible}
                        visibleSetter={setEditResponsibleDialogVisible}
                        saveFunction={saveHearingOrganizationProfile}
                        title={translatedMessage('hearing.responsible')}
                    />
                </>
            )}
        </>
    );
};

export default HearingListComponent;
