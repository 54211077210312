import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import NoticeEditComponent from './NoticeEditComponent';

const NoticeEditDialogComponent = (props) => {
    const [notice, setNotice] = useState(null);

    const toast = useRef(null);


    useEffect(() => {
        let _notice = props.value;
        setNotice(_notice);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setNotice(null)
            props.visibleSetter(false);
        }
    };

    const afterSave = async (savedData, isSuccess, errorMessage = null) => {
        if (typeof props.afterSave === 'function') {
            if (isSuccess) {
                props.afterSave(savedData);
                toast?.current?.show({ severity: 'success', summary: translatedMessage("notice.save.success"), life: 3000 });
            } else {
                toast?.current?.show({ severity: 'error', summary: errorMessage, life: 5000 });
            }
        }
        hideDialog()
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="notice-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('notice.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <NoticeEditComponent
                    value={notice}
                    isNotice={true}
                    afterSave={afterSave}
                    beneficiary={props?.beneficiary}
                    noticeTypeList={props?.noticeTypeList}
                    noticeReporterTypeList={props?.noticeReporterTypeList}
                    noticeReportModeList={props?.noticeReportModeList}
                />
            </Dialog>
        </>);
};

export default NoticeEditDialogComponent;
