import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { EntityLookupService } from '../../service/EntityLookupService';

let emptyEntityLookup = {
    id: null,
    code: '',
    fieldClass: null,
    editable: true,
    enabled: true,
    relatedEntityLookup: null,
    order_value: null,
    property1: '',
    property2: '',
    labelRo: '',
    labelEn: ''
};

const EntityLookupEditDialog = (props) => {
    const [entityLookup, setEntityLookup] = useState(emptyEntityLookup);
    const [isNew, setIsNew] = useState(false);
    const [filteredRelatedEntityLookups, setFilteredRelatedEntityLookups] = useState([]);

    const toast = useRef(null);

    const entityLookupService = useMemo(() => new EntityLookupService(), []);

    useEffect(() => {
        let _entityLookup = props.value ? props.value : emptyEntityLookup;
        if(_entityLookup.relatedEntityLookup && _entityLookup.relatedEntityLookup.code){
            _entityLookup.relatedEntityLookup.displayValue = getEntityLookupDisplayValue(_entityLookup.relatedEntityLookup);
        }        
        setEntityLookup(_entityLookup);
        setIsNew(props.value ? false : true);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveEntityLookup = (data) => {
        let _entityLookup = { ...data };
        _entityLookup.fieldClass = props.fieldClass;
        _entityLookup.editable = true;
        _entityLookup.enabled = true;
        entityLookupService.saveEntityLookup(_entityLookup)
            .then((savedEntityLookup) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedEntityLookup, isNew);
                }
                toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: entityLookup,
        validate: (data) => {
            let errors = {};

            if (!data.code) {
                errors.code = translatedMessage('form.error.entityLookup.code.required');
            }
            if (!data.labelRo) {
                errors.labelRo = translatedMessage('form.error.entityLookup.labelRo.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveEntityLookup(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleListChange = async (data, field) => {
        formik.setFieldValue(field, data.value)
    }

    const searchRelatedEntityLookups = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "label_ro";
        criteria.pageSize = 50;
        criteria.labelRo = query.toLowerCase();

        await entityLookupService
            .getEntityLookupsByCriteria(criteria)
            .then((data) => {
                let _filteredEntityLookups = data.entityLookupDtos
                    .filter(el => el.id !== entityLookup.id)
                    .map(el => { el.displayValue = getEntityLookupDisplayValue(el); return el; });
                setFilteredRelatedEntityLookups(_filteredEntityLookups);
            })
            .catch((error) => {
                //setError(true)
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    }

    const getEntityLookupDisplayValue = (entityLookup) => {
        return entityLookup ? entityLookup.labelRo + " (" + entityLookup.code + ", " + entityLookup.fieldClass + ")" : '-';
    }

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('entityLookup.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="code" name="code" value={formik.values.code} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('code') })} />
                                    <label htmlFor="code" className={classNames({ 'p-error': isFormFieldValid('code') })}>
                                        {translatedMessage('entityLookup.code')}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('code')}
                        </div>

                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="labelRo" name="labelRo" value={formik.values.labelRo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('labelRo') })} />
                                    <label htmlFor="labelRo" className={classNames({ 'p-error': isFormFieldValid('labelRo') })}>
                                        {translatedMessage('entityLookup.labelRo')}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('labelRo')}
                        </div>

                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="labelEn" name="labelEn" value={formik.values.labelEn ?? ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('labelEn') })} />
                                    <label htmlFor="labelEn" className={classNames({ 'p-error': isFormFieldValid('labelEn') })}>
                                        {translatedMessage('entityLookup.labelEn')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('labelEn')}
                        </div>

                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="property1" name="property1" value={formik.values.property1 ?? ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('property1') })} />
                                    <label htmlFor="property1" className={classNames({ 'p-error': isFormFieldValid('property1') })}>
                                        {translatedMessage('entityLookup.property1')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('property1')}
                        </div>

                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="relatedEntityLookup"
                                        name="relatedEntityLookup"
                                        value={formik.values.relatedEntityLookup}
                                        suggestions={filteredRelatedEntityLookups}
                                        completeMethod={(e) => searchRelatedEntityLookups(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="displayValue"
                                        onChange={(d) => handleListChange(d, "relatedEntityLookup")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('relatedEntityLookup') })}
                                    />
                                    <label htmlFor="relatedEntityLookup" className={classNames({ 'p-error': isFormFieldValid('relatedEntityLookup') })}>
                                        {translatedMessage("entityLookup.parentEntity")}
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default EntityLookupEditDialog;
