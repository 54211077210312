import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { SolutionService } from "../../service/SolutionService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from "../../service/EnumService";
import DeleteModalComponent from "../DeleteModalComponent";
import SolutionEditDialogComponent from "./SolutionEditDialogComponent";
import DataTableUtils from "../../utilities/DataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import SolutionViewDialogComponent from "./SolutionViewDialogComponent";
import SecurityService from '../../service/SecurityService';

const sortOrderHeader = ["issuer", "documentNumber", "documentDate", "type", "beneficiary", "createdOn"]

const tableHeader = [
    translatedMessage("solution.issuer"),
    translatedMessage("solution.documentNumber"),
    translatedMessage("solution.documentDate"),
    translatedMessage("solution.solutionType"),
    translatedMessage("solution.beneficiary"),
    translatedMessage("generic.created.on")
]

const SolutionListComponent = (props) => {
    const [solutions, setSolutions] = useState([]);
    const [selectedSolution, setSelectedSolution] = useState(null);

    const [solutionTypeList, setSolutionTypeList] = useState([])

    const [canCreate, setCanCreate] = useState(true);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            issuer: { value: null, matchMode: FilterMatchMode.CONTAINS },
            documentNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
            documentDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.IN },
            beneficiaryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);

    const propToColumnMap = {
        issuer: "issuer",
        documentNumber: "document_number",
        documentDate: "document_date",
        type: "solution_type",
        beneficiaryName: "beneficiary_name",
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();

    const solutionService = useMemo(() => new SolutionService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getSolutionTypes = async () => {
            const _solutionTypes = await EnumService.getEntityLookupByField("NOM:SOLUTION_TYPE")
            setSolutionTypeList(_solutionTypes)
        }


        const checkCanCreate = async () => {
            if (props?.beneficiary?.id) {
                setCanCreate(await SecurityService.check('ADD_SOLUTION', props?.beneficiary?.id));
            } else {
                setCanCreate(await SecurityService.check('ADD_SOLUTION'));
            }
        };

        getSolutionTypes();
        checkCanCreate()
        setFirstLoading(false);
    }, [solutionService, props]);

    useEffect(() => {
        loadSolutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("SolutionList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";
        criteria.beneficiaryName = lazyParams.filters.beneficiaryName.value;
        criteria.documentNumber = lazyParams.filters.documentNumber.value;
        criteria.solutionTypeIds = lazyParams.filters.type.value;
        criteria.issuer = lazyParams.filters.issuer.value;
        criteria.beneficiaryId = props?.beneficiary?.id;
        return criteria;
    };

    const loadSolutions = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await solutionService
            .getSolutionsByCriteria(criteria)
            .then((data) => {
                setSolutions(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedSolution, isNew) => {
        loadSolutions();
    };

    const showSolutionViewPage = (solution) => {
        if (props?.showViewInDialog) {
            setSelectedSolution(solution)
            setViewDialogVisible(true)
        } else {
            navigate(`/solution/${solution.id}`);
        }
    };

    const showNewDialog = () => {
        setSelectedSolution(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (solution) => {
        setSelectedSolution(solution);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (solution) => {
        setSelectedSolution(solution);
        setDeleteDialogVisible(true);
    };

    const deleteSolution = () => {
        solutionService
            .deleteSolution(selectedSolution.id)
            .then(() => {
                let _solutions = solutions.filter(
                    (item) => item.id !== selectedSolution.id
                );
                setSolutions(_solutions);
                toast?.current?.show({
                    severity: "success",
                    summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedSolution(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("SOLUTION_CREATE") &&
                        (
                            <Button
                                label={translatedMessage("solution.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            solutionService.getSolutionsByCriteria(criteria)
                .then((data) => {
                    let exportData = data.items.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item;
                        exportItem.type = item.type?.label;
                        exportItem.beneficiary = item.beneficiary?.profile?.fullName;
                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn)
                        return exportItem;
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.computeExportFileName("solution.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-solution justify-content-end">
                {hasPermission("SOLUTION_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showSolutionViewPage(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_SOLUTION}
                        />
                    )}
                {hasPermission("SOLUTION_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_SOLUTION}
                        />
                    )}
                {hasPermission("SOLUTION_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_SOLUTION}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateTemplate(rowData.documentDate)
    }

    const beneficiaryBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button
                            text
                            label={rowData.beneficiary?.profile?.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiary?.id}/solution`)}
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.beneficiary?.profile?.fullName
                    }
                </div>
            </>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-solution">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{props.pageTitle ? props.pageTitle : translatedMessage("solution.list")}</h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={solutions}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        <Column
                            field="issuer"
                            header={translatedMessage("solution.issuer")}
                            headerStyle={{ minWidth: "8rem" }}
                            sortable
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="documentNumber"
                            header={translatedMessage("solution.documentNumber")}
                            headerStyle={{ minWidth: "8rem" }}
                            sortable
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="documentDate"
                            header={translatedMessage("solution.documentDate")}
                            headerStyle={{ minWidth: "8rem" }}
                            body={dateBodyTemplate}
                            sortable
                        />
                        <Column
                            field="type"
                            header={translatedMessage("solution.solutionType")}
                            body={(e) => e.type?.label}
                            headerStyle={{ minWidth: "8rem" }}
                            sortable
                            filter
                            filterElement={(e) => DataTableUtils.entityLookupFilterTemplate(e, solutionTypeList, translatedMessage('solution.solutionType'))}
                            filterHeaderClassName="pcn-filter-column-250"
                            showFilterMenu={false}
                        />
                        {!props?.beneficiary &&
                            <Column
                                field="beneficiaryName"
                                header={translatedMessage("solution.beneficiary")}
                                headerStyle={{ minWidth: "8rem" }}
                                body={beneficiaryBodyTemplate}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <SolutionEditDialogComponent
                        value={selectedSolution}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        beneficiary={props?.beneficiary}
                        solutionTypeList={solutionTypeList}
                    />

                    <SolutionViewDialogComponent
                        value={selectedSolution}
                        visible={viewDialogVisible}
                        visibleSetter={setViewDialogVisible}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedSolution ? selectedSolution.name : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteSolution()}
                        info="solution.delete.info"
                    />
                </>
            )}
        </>
    );
};

export default SolutionListComponent;
