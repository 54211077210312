import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { ServicesService } from "../../service/ServicesService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from "../../service/EnumService";
import DeleteModalComponent from "../DeleteModalComponent";
import ServiceEditDialog from "./ServiceEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import ServiceDataTableUtils from "../../utilities/ServiceDataTableUtils";
import useAuth from "../../hooks/useAuth";
import ExportButtonComponent from "../ExportButtonComponent";
import SecurityService from '../../service/SecurityService';

const ServiceListComponent = (props) => {
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);

    const [canCreate, setCanCreate] = useState(false);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            organizationName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            categoryName: { value: null, matchMode: FilterMatchMode.IN },
            typeName: { value: null, matchMode: FilterMatchMode.IN },
            centerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        organizationName: "organization_name",
        categoryName: "category_name",
        typeName: "type_name",
        centerName: "center_name"
    };

    const [totalRecords, setTotalRecords] = useState(0);

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();
    const navigate = useNavigate();

    const servicesService = useMemo(() => new ServicesService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getServiceCategories = async () => {
            const _serviceCategories = await EnumService.getEntityLookupByField("NOM:SERVICE_CATEGORY");
            setServiceCategories(_serviceCategories);
        };

        const getServiceTypes = async () => {
            const _serviceTypes = await EnumService.getEntityLookupByField("NOM:SERVICE_TYPE");
            setServiceTypes(_serviceTypes);
        };

        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_ORGANIZATION'));
        };

        getServiceCategories();
        getServiceTypes();
        checkCanCreate()
        setFirstLoading(false);
    }, [servicesService, props]);

    useEffect(() => {
        loadServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("ServicesList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";

        criteria.organizationId = props?.organization?.id;
        criteria.centerId = props?.center?.id;

        criteria.organizationName = lazyParams.filters.organizationName.value;
        criteria.categories = lazyParams.filters.categoryName.value;
        criteria.types = lazyParams.filters.typeName.value;
        criteria.centerName = lazyParams.filters.centerName.value;
        
        return criteria;
    };

    const loadServices = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await servicesService
            .getServiceViewByCriteria(criteria)
            .then((data) => {
                setServices(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedService, isNew) => {
        loadServices();
    };

    const showServiceViewPage = (service) => {
        navigate(`/service/${service.id}`);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedService(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = async (service) => {
        setSelectedService(await servicesService.getService(service?.id));
        setEditDialogVisible(true);
    };

    const showDeleteDialog = async(service) => {
        setSelectedService(await servicesService.getService(service?.id));
        setDeleteDialogVisible(true);
    };

    const deleteService = () => {
        servicesService
            .deleteService(selectedService.id)
            .then(() => {
                let _services = services.filter(
                    (item) => item.id !== selectedService.id
                );
                setServices(_services);
                toast?.current?.show({
                    severity: "success", summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast?.current?.show({ severity: "error", summary: translatedMessage(error), life: 5000, });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedService(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("SERVICE_CREATE") && 
                        (
                            <Button
                                label={translatedMessage("service.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            servicesService
                .getServiceViewByCriteria(criteria)                
                .then((data) => {
                    let exportData = data.items.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item
                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn)

                        delete item.categoryCode
                        delete item.categoryId
                        delete item.centerId
                        delete item.id
                        delete item.organizationId
                        delete item.organizationType
                        delete item.serviceName
                        delete item.typeCode
                        delete item.typeId

                        return exportItem;
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ServiceDataTableUtils.serviceListTableHeader()}
                sortOrderHeader={ServiceDataTableUtils.serviceListSortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("service.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-service justify-content-end">
                {hasPermission("SERVICE_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showServiceViewPage(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_SERVICES}
                        />
                    )}
                {hasPermission("SERVICE_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_SERVICES}
                        />
                    )}
                {hasPermission("SERVICE_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_SERVICES}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    const organizationBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('ORGANIZATION_VIEW') &&
                        <Button
                            text
                            label={rowData.organizationName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/${rowData.organizationType?.toLowerCase()}/${rowData.organizationId}`)}
                        />
                    }
                    {!hasPermission('ORGANIZATION_VIEW') &&
                        rowData.organizationName
                    }
                </div>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-service">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className='mb-0'>{props.pageTitle ? props.pageTitle : translatedMessage("service.list")}</h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" />

                    <DataTable
                        ref={dt}
                        value={services}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        {!props?.organization &&
                            <Column
                                field="organizationName"
                                header={translatedMessage("service.organization")}
                                headerStyle={{ minWidth: "8rem" }}
                                body={organizationBodyTemplate}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        <Column
                            field="categoryName"
                            header={translatedMessage("service.category")}
                            headerStyle={{ minWidth: "8rem" }}
                            sortable
                            filter
                            filterElement={(e) => ServiceDataTableUtils.entityLookupFilterTemplate(e, serviceCategories, translatedMessage('service.category'))}
                            filterHeaderClassName="pcn-filter-column-300"
                            showFilterMenu={false}
                        />
                        <Column
                            field="typeName"
                            header={translatedMessage("service.type")}
                            headerStyle={{ minWidth: "8rem" }}
                            sortable
                            filter
                            filterElement={(e) => ServiceDataTableUtils.entityLookupFilterTemplate(e, serviceTypes, translatedMessage('service.type'))}
                            filterHeaderClassName="pcn-filter-column-300"
                            showFilterMenu={false}
                        />
                        {!props?.center &&
                            <Column
                                field="centerName"
                                header={translatedMessage("service.center")}
                                headerStyle={{ minWidth: "8rem" }}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <ServiceEditDialog
                        value={selectedService}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        serviceCategories={serviceCategories}
                        serviceTypes={serviceTypes}
                        organization={props?.organization}
                        center={props?.center}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedService ? selectedService.categoryName + ', ' + selectedService.organizationName + ', ' + selectedService.centerName : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteService()}
                        info="service.delete.info"
                    />
                </>
            )}
        </>
    );
};

export default ServiceListComponent;
