import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getIn, useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import { ServicesService } from '../../service/ServicesService';
import { CenterService } from '../../service/CenterService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { OrganizationService } from '../../service/OrganizationService';

let emptyService = {
    id: null,
    name: '',
    category: null,
    type: null,
    objective: '',
    organization: null,
    center: null,
    startDate: null,
    endDate: null
};

const ServiceEditDialog = (props) => {
    const [service, setService] = useState(emptyService);
    const [isNew, setIsNew] = useState(false);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);
    const [filteredCenters, setFilteredCenters] = useState([]);

    const toast = useRef(null);

    const servicesService = useMemo(() => new ServicesService(), []);
    const organizationService = useMemo(() => new OrganizationService(), []);
    const centerService = useMemo(() => new CenterService(), []);

    useEffect(() => {
        let _service = props.value;
        if (!_service) {
            _service = { ...emptyService };
            if (props?.organization) { _service.organization = props?.organization }
            if (props?.center) { _service.center = props?.center; }
        }
        setService(_service);
        setIsNew(props.value ? false : true);
        setServiceCategories(props?.serviceCategories);
        setServiceTypes(props?.serviceTypes);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveService = async (data) => {
        // console.log("save service data: ", data)
        let _service = GeneralUtils.nullIfEmptyForObjectFields(data);
        await servicesService.saveService(_service)
            .then((savedService) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedService, isNew);
                }
                toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: service,
        validate: (data) => {
            let errors = {};

            if (!data.organization) {
                errors.organization = translatedMessage('form.error.organization.required');
            }
            if (!data.category) {
                errors.category = translatedMessage('form.error.category.required');
            }
            if (!data.type) {
                errors.type = translatedMessage('form.error.type.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveService(data);
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const searchOrganizations = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "name";
        criteria.types = ['PROVIDER', 'DEPARTMENT'];
        criteria.name = query.toLowerCase();

        organizationService.getOrganizationsByCriteria(criteria)
            .then((result) => {
                setFilteredOrganizations(result.items);
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    const searchCenters = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "name";
        criteria.types = ['CENTER'];
        criteria.name = query.toLowerCase();

        centerService.getCentersByCriteria(criteria)
            .then((result) => {
                setFilteredCenters(result.items);
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('service.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="organization"
                                        name="organization"
                                        value={formik.values.organization}
                                        suggestions={filteredOrganizations}
                                        completeMethod={(e) => searchOrganizations(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="name"
                                        onChange={formik.handleChange}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('organization') })}
                                        autoFocus
                                        disabled={props?.organization}
                                    />
                                    <label htmlFor="organization" className={classNames({ 'p-error': isFormFieldValid('organization') })}>
                                        {translatedMessage("service.organization")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('organization')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="category.code" name="category.code" value={formik.values.category?.code} onChange={formik.handleChange} options={serviceCategories} optionValue='code'
                                        className={classNames({ 'p-invalid': isFormFieldValid('category') })} />
                                    <label htmlFor="category.code" className={classNames({ 'p-error': isFormFieldValid('category') })}>
                                        {translatedMessage('service.category')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('category')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="type.code" name="type.code" value={formik.values.type?.code} onChange={formik.handleChange} options={serviceTypes} optionValue='code'
                                        className={classNames({ 'p-invalid': isFormFieldValid('type') })} />
                                    <label htmlFor="type.code" className={classNames({ 'p-error': isFormFieldValid('type') })}>
                                        {translatedMessage('service.type')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('type')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="center"
                                        name="center"
                                        value={formik.values.center}
                                        suggestions={filteredCenters}
                                        completeMethod={(e) => searchCenters(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="name"
                                        onChange={formik.handleChange}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('center') })}
                                    />
                                    <label htmlFor="center" className={classNames({ 'p-error': isFormFieldValid('center') })}>
                                        {translatedMessage("service.center")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('center')}
                        </div>
                    </div>
                </form>
            </Dialog>
        </>);
};

export default ServiceEditDialog;
