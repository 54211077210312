import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { Link } from 'react-router-dom';

import { StorageService } from '../../service/StorageService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import StorageFileUpload from '../../components/file/StorageFileUpload';
import StorageFileDownloadTableButton from './StorageFileDownloadTableButton';
import DeleteModalComponent from '../DeleteModalComponent';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import FilePropertiesEditDialogComponent from './FilePropertiesEditDialogComponent';
import StorageFileViewTableButton from './StorageFileViewTableButton';

const StorageFolderFileList = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fileList, setFileList] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [usageLookup, setUsageLookup] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'issuer': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'registrationNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'usageId': { value: null, matchMode: FilterMatchMode.IN },
    });

    const storageService = useMemo(() => new StorageService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getFiles = async () => {
            const usageLookupResponse = await EnumService.getFileUsageByEntity(props?.entityName ? props?.entityName : null);
            setUsageLookup(usageLookupResponse)

            await storageService.getFilesInFolder(props.folderId)
                .then(filesResponse => {
                    setFileList(filesResponse)
                    setIsLoading(false)
                })
                .catch(error => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        if (props.folderId) {
            getFiles();
        } else {
            setIsLoading(false)
        }
    }, [props, storageService]);

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div className="mt-2 md:mt-0 mr-2">
                {props.showUploadButton &&
                    <Button label={translatedMessage("generic.files.upload")} icon="pi pi-plus" className="p-button-primary mr-2 my-1 md:my-1 white-space-nowrap"
                        onClick={() => handleUpload()} visible={!props.readOnly} disabled={!props?.folderId} type='button' />
                }
                <Button label={translatedMessage("file.downloadAll")} icon="pi pi-download" className="p-button-primary mr-2 my-1 md:my-1 white-space-nowrap"
                    onClick={() => handleDownloadAll()} disabled={fileList && fileList.length === 0} type='button' />
                {props?.beneficiary &&
                    <Button label={translatedMessage("beneficiary.file.downloadAll")} icon="pi pi-download" className="p-button-primary mr-2 my-1 md:my-1 white-space-nowrap"
                        onClick={() => handleDownloadAllForBeneficiary()} type='button' />
                }
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {<StorageFileViewTableButton fileUuid={rowData.uuid} fileName={rowData.name} />}

                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info m-1"
                    title={translatedMessage("generic.edit")}
                    visible={!props.readOnly}
                    onClick={() => handleEdit(rowData)}
                    type='button'
                />

                {<StorageFileDownloadTableButton fileUuid={rowData.uuid} fileName={rowData.name} />}

                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning m-1"
                    title={translatedMessage("generic.delete")}
                    visible={!props.readOnly}
                    onClick={() => handleDelete(rowData)}
                    type='button'
                />
            </div>
        );
    };

    const sizeBodyTemplate = (rowData) => {
        let roundedSize = (rowData.size / (1024 * 1024)).toFixed(2)
        return roundedSize.toString().concat(" MB");
    }

    const handleDownloadAll = () => {
        if (props.folderId) {
            storageService.downloadFilesInFolder(props.folderId, props.folderName?.replaceAll(' ', '_').toLowerCase())
                .catch((errorMessage) => toast?.current?.show({ severity: 'error', summary: errorMessage, life: 5000 }));
        }
    }

    const handleDownloadAllForBeneficiary = () => {
        if (props.beneficiary) {
            storageService.downloadBeneficiaryFiles(props.beneficiary.id, props.beneficiary?.profile?.fullName.replaceAll(' ', '_').toLowerCase())
                .catch((errorMessage) => toast?.current?.show({ severity: 'error', summary: errorMessage, life: 5000 }));
        }
    }

    const handleEdit = (data) => {
        setSelectedValue(data)
        setShowEditDialog(true)
    }

    const handleDelete = (data) => {
        setSelectedValue(data)
        setShowDeleteDialog(true)
    }

    const deleteRecord = () => {
        storageService.deleteFile(selectedValue.uuid)
            .then(() => {
                let _fileList = fileList.filter(item => item.id !== selectedValue.id)
                setFileList(_fileList)
                setShowDeleteDialog(false);

                toast?.current?.show({ severity: 'success', summary: translatedMessage("generic.delete.success") });
            })
            .catch(error => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
    };

    const handleUpload = () => {
        if(props.folderId) {
            setShowUploadDialog(true)
        }
    };

    const onFileUploadSuccess = (id, response) => {
        let _fileList = fileList.map(item => ({ ...item }))
        if (response?.length > 0) {
            response.forEach(item => {
                item.registrationDate = item.registrationDate ? new Date(Date.parse(item.registrationDate)) : ''
                _fileList.push(item)
            })
        }

        setFileList(_fileList)
        toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast?.current?.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const updateList = async (savedData) => {
        let updatedFiles = DataTableUtils.updateTableList(fileList, savedData, false)

        setFileList(updatedFiles);
    };

    const usageFilterTemplate = (options) => {
        return <MultiSelect
            value={options.value}
            options={usageLookup}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={translatedMessage('generic.type')}
            className="p-column-filter"
            maxSelectedLabels={1}
            showSelectAll={false}
            selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
            itemTemplate={(e) => e.label}
        />;
    }

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />

                {props.pageTitle &&
                    <div className='w-full text-align-left'>
                        <h5 className='mb-2'>{props.pageTitle}</h5>
                    </div>
                }
                {props.pageSubTitle &&
                    <div className='w-full text-align-left'>{props.pageSubTitle}</div>
                }
                {props.pageInfo &&
                    <div className='w-full text-align-left mt-2'>{props.pageInfo}</div>
                }
                {props.pageInfoLink && props.pageInfoLinkText &&
                    <div className='w-full text-align-left mt-2'>
                        {props.pageInfoLinkText + " "}
                        <Link to={props.pageInfoLink}>
                            <span className='font-bold underline'>{translatedMessage('generic.here').toString().toUpperCase() + "."}</span>
                        </Link>
                    </div>
                }
                <DataTable
                    ref={dt}
                    value={fileList}
                    dataKey="id"
                    paginator={props?.hidePaginator ? false : true}
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={props?.hidePaginator ? 25 : DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                    header={header}
                    filterDisplay="row"
                    filters={filters}
                    globalFilterFields={['name', 'issuer', 'registrationNumber']}
                >
                    <Column
                        field="name"
                        header={translatedMessage("generic.nameRo")}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                        filter
                        showFilterMenu={false}
                    />
                    {props.hasFileProperties &&
                        <Column
                            field="issuer"
                            header={translatedMessage("file.issuer")}
                            sortable
                            headerStyle={{ width: '20%', minWidth: '8rem' }}
                            filter
                            showFilterMenu={false}
                        />
                    }
                    {props.hasFileProperties &&
                        <Column
                            field="registrationNumber"
                            header={translatedMessage("file.registrationNumber")}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            filter
                            showFilterMenu={false}
                        />
                    }
                    {props.hasFileProperties &&
                        <Column
                            field="registrationDate"
                            header={translatedMessage("file.registrationDate")}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            body={(e) => GeneralUtils.formatDate(e.registrationDate)}
                        />
                    }
                    <Column
                        field="usageId"
                        header={translatedMessage("file.usage")}
                        sortable
                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                        body={(e) => e.usageLabel}
                        filter
                        filterElement={(e) => usageFilterTemplate(e)}
                        showFilterMenu={false}
                    />
                    <Column
                        field="size"
                        header={translatedMessage("file.size")}
                        sortable
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                        body={sizeBodyTemplate}
                    />
                    <Column body={actionBodyTemplate} />
                </DataTable>

                <StorageFileUpload
                    entityId={null}
                    folderId={props.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={props.multiple}
                    accept={["pdf", "image"]}
                    visible={showUploadDialog}
                    visibleSetter={setShowUploadDialog}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                    usageLookup={usageLookup}
                    hasFileProperties={props.hasFileProperties}
                />

                <DeleteModalComponent
                    visible={showDeleteDialog}
                    item={selectedValue && selectedValue.name}
                    closeDialog={() => setShowDeleteDialog(false)}
                    deleteRecord={() => deleteRecord()}
                />

                <FilePropertiesEditDialogComponent
                    value={selectedValue}
                    visible={showEditDialog}
                    afterSave={(savedData) => updateList(savedData)}
                    visibleSetter={() => setShowEditDialog(false)}
                    usageLookup={usageLookup}
                />
            </>
        );
    }
};

export default StorageFolderFileList;