import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';

import { Toast } from 'primereact/toast';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { MonitoringService } from '../../service/MonitoringService';
import MonitoringToolbar from './MonitoringToolbar';
import MonitoringListComponent from '../../components/monitoring/MonitoringListComponent';

const MonitoringHierarchyList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [monitoring, setMonitoring] = useState(null);
    const [error] = useState({ isError: false, message: '' });
    const toast = useRef(null);

    const monitoringService = useMemo(() => new MonitoringService(), []);

    const { monitoringIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getMonitoring = async () => {
            await monitoringService.getMonitoring(monitoringIdParam)
                .then(async (_data) => {
                    setMonitoring(_data);

                    // await checkEditPermission()

                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getMonitoring();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monitoringService, monitoringIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <div className="grid h-full">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card h-full">
                        <MonitoringToolbar monitoring={monitoring} code="HIERARCHY" />

                        <MonitoringListComponent
                            parentMonitoring={monitoring}
                            pageTitle={translatedMessage("monitoring.hierarchy")}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default MonitoringHierarchyList;
